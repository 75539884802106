import { Button, Card, FormFeedback, FormGroup, Icon, Input, Label, Spinner } from '@efilecabinet/efc-atlantis-components';
import { IUseFormObject } from '../../../utils/useForm';
import { useState } from 'react';
import { AuthProps } from '../../../api/useAuthApi';
import { ResetPasswordTypeEnum, useResetPasswordApi } from '../../../api/useResetPasswordApi';
import { useEmailFormValidator } from '../../../utils/useEmailFormValidator';
import { useLoginNavigation } from '../../../utils/useLoginNavigation';
import { RoutePath } from '../Login';

export interface NewPasswordInputProps {
    loginForm: IUseFormObject<AuthProps>;
    show: boolean;
}

export const NewUser = (props: NewPasswordInputProps) => {

    const { loginForm, show } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [sendSuccessful, setResendSuccessful] = useState(false);

    const navigate = useLoginNavigation();
    const { validateEmailForm } = useEmailFormValidator();
    const { sendResetRequest } = useResetPasswordApi();

    const checkEmailForm = () => {
        if (validateEmailForm(loginForm.model.username)) {
            return true;
        }

        loginForm.addError('username', 'Invalid Email Address');
        return false;
    };

    const validateEmailInput = () => {
        if (!loginForm.model.username) {
            loginForm.addError('username', 'Required.');
            return false;
        }

        return true;
    }

    const onSendEmail = async () => {
        if (!validateEmailInput() || !checkEmailForm()) {
            return;
        }

        loginForm.clearErrors();
        await sendNewUserEmail();
    };

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await onSendEmail();
        }
    };

    const tryGetRegionName = () => {
        if (!!loginForm.model.revverInstance) {
            return loginForm.model.revverInstance.name;
        }

        if (!!loginForm.model.regionIdentifier) {
            return loginForm.model.regionIdentifier;
        }

        return null;
    };

    const sendNewUserEmail = async () => {
        const regionName = tryGetRegionName();

        if (!!loginForm.model.username && !!regionName) {
            setIsLoading(true);
            try {
                await sendResetRequest(loginForm.model.username, regionName, ResetPasswordTypeEnum.Email, true);
                navigate(RoutePath.NewUserEmail);
            }
            catch (error: any) {
                setResendSuccessful(false);
                let errorData = error?.response?.data;
                if (!!errorData) {
                    loginForm.addError('modelErrors', errorData);
                }
                else {
                    loginForm.addError('modelErrors', error);
                }
            }
            setIsLoading(false);
        }
        else {
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
                navigate(RoutePath.NewUserEmail);
            }, 1000);
        }
    };

    return (
        <>
            {show &&
                <>
                    <Card.Title size='xl' bold className='mx-3 mt-4'>
                        Welcome!
                    </Card.Title>
                    <Card.Subtitle className='mx-3'>
                        {!!loginForm.model.newUserExpired ?
                            'Your link has expired. Resend the email with instructions for setting up your account.' :
                            'If an account is associated with your email address, an email will be sent with instructions to sign in.'
                        }
                    </Card.Subtitle>
                    <Card.Body>
                        <>
                            <FormGroup className='mt-2'>
                                <Label for='username'>Email Address</Label>
                                <Input id='username' name='username' value={loginForm.model.username} invalid={!!loginForm.errors?.username} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} autoFocus />
                                <FormFeedback>{loginForm.errors?.username}</FormFeedback>
                            </FormGroup>
                        </>
                        <Button className='mx-auto w-100 mt-3' onClick={onSendEmail} >
                            {isLoading &&
                                <Spinner size='sm' className='my-auto' />
                            }
                            {!isLoading &&
                                <>
                                    Send Email
                                </>
                            }
                        </Button>
                    </Card.Body>
                </>
            }
        </>
    );
};
