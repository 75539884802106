import React, { useEffect, useState } from 'react';
import { Card, Text, Spinner, Alert } from '@efilecabinet/efc-atlantis-components';
import './OutgoingEmailOptions.css';
import { OptionsTile } from '../../../../components/OptionsTile';
import { AuthProps } from '../../../../api/useAuthApi';
import { IUseFormObject } from '../../../../utils/useForm';
import { useLoginHandler } from '../../useLoginHandler';
import { useOutgoingEmailApi } from '../../../../api/useOutgoingEmailApi';
import { EmailProviderAnonymous, EmailSettingTypeEnum } from '../OutgoingEmailTypes';
import { ExternalAuthenticationAreaEnum, ExternalAuthenticationProviderEnum, ExternalOAuth } from '../../../AuthenticationTypes';
import { CreateCenteredPopup } from '../../../../utils/PopupUtility';
import { Modal } from 'reactstrap';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';
import { RoutePath } from '../../Login';

export interface OutgoingEmailOptionsProps {
    loginForm: IUseFormObject<AuthProps>;
    show: boolean;
}

interface EmailSettingOption {
    type: ExternalAuthenticationProviderEnum;
    displayName: string;
    url: string;
    showSpinner: boolean;
    selected: boolean;
}

export const OutgoingEmailOptions = (props: OutgoingEmailOptionsProps) => {
    const { show, loginForm } = props;

    const navigate = useLoginNavigation();

    const { login, loginWithLimitedAccess } = useLoginHandler(loginForm);
    const { getEmailProviders, getEmailSettings, validateEmailSettings } = useOutgoingEmailApi();

    const [authModalIsOpen, setAuthModalIsOpen] = useState(false);
    const [emailSettingOptions, setEmailSettingOptions] = useState<EmailSettingOption[]>([]);
    const [limitedLoginLoading, setLimitedLoginLoading] = useState(false);
    const [loadingAuthentications, setLoadingAuthentications] = useState(false);
    const [validationError, setValidationError] = useState('');

    const REFRESH_TIME_SPAN_IN_MILLISECONDS = 2000;

    const mapEmailSettings = (authentications: ExternalOAuth[]) => {
        setEmailSettingOptions(
            authentications.map((auth) => ({
                type: auth.authenticationType,
                url: auth.authenticationUrl,
                displayName: auth.displayName,
                showSpinner: false,
                selected: false,
            }))
        );
    };

    const updateSelection = (option: EmailSettingOption, loading: boolean, selected: boolean) => {
        let currentOptions = [...emailSettingOptions];
        let index = currentOptions.findIndex((currentOption) => currentOption.type === option.type);
        currentOptions[index].showSpinner = loading;
        currentOptions[index].selected = selected;
        setEmailSettingOptions(currentOptions);
    };

    const openExternalAuthWindow = (option: EmailSettingOption, loginForm: IUseFormObject<AuthProps>) => {
        let loadingSpinner = true;
        let selected = true;
        updateSelection(option, loadingSpinner, selected);

        setValidationError('');
        loginForm.clearError('modelErrors');

        let authWindow = CreateCenteredPopup(option.url, `${option.displayName} External Authentication`, 550, 550);
        setAuthModalIsOpen(true);

        let timer = setInterval(async () => {
            if (!authWindow || authWindow?.closed) {
                clearInterval(timer);
                setAuthModalIsOpen(false);
                checkForValidEmailSetting(option);
            }
        }, REFRESH_TIME_SPAN_IN_MILLISECONDS);
    };

    const checkForValidEmailSetting = async (option: EmailSettingOption) => {
        let loadingSpinner = false;
        let selected = false;

        try {
            let user = await getEmailSettings(loginForm.model.username, loginForm.model.revverInstance?.name);

            if (user.emailSettings.length > 0) {
                await validateEmailSettings(user.id, loginForm.model.revverInstance?.name);
                await login();
            } else {
                // In case the auth window is closed without completing OAuth
                updateSelection(option, loadingSpinner, selected);
            }
        } catch (exception: any) {
            updateSelection(option, loadingSpinner, selected);
            if (exception?.response?.data) {
                setValidationError(exception?.response?.data);
            } else {
                loginForm.addError('modelErrors', `${option.displayName} connection failed. Try again or choose a different connection.`);
            }
        }
    };

    const limitedAccessLogin = async () => {
        setLimitedLoginLoading(true);
        await loginWithLimitedAccess();
        setLimitedLoginLoading(false);
    };

    useEffect(() => {
        const getExternalEmailProviders = async () => {
            setLoadingAuthentications(true);

            const instanceName = loginForm.model.revverInstance?.name;
            const anonymousAccess: EmailProviderAnonymous = {
                anonymousEndpointAccess: {
                    userName: loginForm.model.username,
                    password: loginForm.model.password,
                    samlToken: loginForm.model.samlToken,
                },
                authenticationId: 0,
                externalAuthenticationArea: ExternalAuthenticationAreaEnum.User,
                settingType: EmailSettingTypeEnum.SMTP,
                userId: loginForm.model?.userId!,
                instanceName: instanceName!,
            };

            try {
                const response = await getEmailProviders(anonymousAccess);
                mapEmailSettings(response);
            } catch (error) {
                console.log({ error });
            } finally {
                setLoadingAuthentications(false);
            }
        };

        if (emailSettingOptions.length === 0) {
            getExternalEmailProviders();
        }
    }, [loginForm, emailSettingOptions]);

    return (
        <>
            {show && (
                <Card.Body className='d-flex flex-column'>
                    <Alert color='danger' isOpen={!!validationError} fade>
                        {validationError}
                    </Alert>
                    <Card.Title size='xl' bold>
                        Outgoing Email Setting
                    </Card.Title>
                    {loadingAuthentications && <Spinner className='mx-auto' />}
                    {!loadingAuthentications &&
                        <>
                            <Card.Subtitle className='mb-3 subtitle'>Your account requires you to set up the outgoing email address that will be used to send emails to people outside of your organization through Revver.</Card.Subtitle>

                            {emailSettingOptions.map((option) =>
                                <OptionsTile key={option.type} onClick={() => openExternalAuthWindow(option, loginForm)} selected={option.selected}>
                                    {option.showSpinner && <Spinner className='my-auto me-2' size='sm' color='primary' />}
                                    Set up with {option.displayName}
                                </OptionsTile>
                            )}

                            <OptionsTile onClick={() => navigate(RoutePath.OutgoingEmailManual)}>Manual Connection</OptionsTile>
                            {loginForm.model.limitedAccess !== undefined &&
                                <OptionsTile onClick={limitedAccessLogin} emphasis='low'>
                                    {limitedLoginLoading && <Spinner className='my-auto me-2' size='sm' color='primary' />}
                                    <Text>
                                        Skip set up and
                                        <div>sign in with limited access</div>
                                    </Text>
                                </OptionsTile>
                            }
                        </>
                    }
                </Card.Body>
            )}
            <Modal backdrop='static' style={{ display: 'none' }} isOpen={authModalIsOpen} />
        </>
    );
};
