import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router";
import { RoutePath } from '../auth/Login/Login';

export const useLoginNavigation = () => {
    const { search } = useLocation();
    const navigate = useNavigate();

    return (path: RoutePath, clearParams?: boolean) => {
        if (!!clearParams) {
            navigate(path);
        } else {
            navigate(path + search);
        }
    }
};
