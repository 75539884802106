import { Button, Card, FormFeedback, FormGroup, Icon, Input, Label, Spinner, Text } from '@efilecabinet/efc-atlantis-components';
import { IUseFormObject } from '../../../../utils/useForm';
import './NewPasswordInput.css';
import { ISecurityPolicy } from '../../../../utils/CommonTypes';
import { useEffect, useState } from 'react';
import { PasswordValidationProps, useNewPasswordValidator } from './useNewPasswordValidator';
import { AuthProps } from '../../../../api/useAuthApi';
import { useResetPasswordApi } from '../../../../api/useResetPasswordApi';
import { DisplayPasswordCompliance } from './DisplayPasswordCompliance';
import { RoutePath } from '../../Login';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';

export interface NewPasswordInputProps {
    loginForm: IUseFormObject<AuthProps>;
    show: boolean;
    isNewUser?: boolean;
}

export const NewPasswordInput = (props: NewPasswordInputProps) => {

    const navigate = useLoginNavigation();

    const { loginForm, show, isNewUser } = props;

    const resetPasswordApi = useResetPasswordApi();

    const [validationProps, setPasswordValidationProps] = useState<PasswordValidationProps>();
    const [securityPolicies, setSecurityPolicies] = useState<ISecurityPolicy[]>();
    const [isLoading, setIsLoading] = useState(false);

    const validator = useNewPasswordValidator(securityPolicies);

    const validIcon = <Icon iconName='circle-check' color='green' />;
    const invalidIcon = <Icon iconName='circle-x' color='red' />;
    const emptyIcon = <Icon iconName='circle-plus' regular />;

    const getPasswordValidationProps = () => {
        return validator.getPasswordValidationProps(loginForm.model.newPassword, loginForm.model.matchPassword);
    };

    const isNewPasswordValid = () => {
        if (!loginForm.model.matchPassword || !loginForm.model.newPassword) {
            return false;
        }

        let isValid = validationProps?.passwordLength && validationProps?.passwordMatch;

        if (isValid && validationProps?.passwordLowerCase !== undefined) {
            isValid = validationProps.passwordLowerCase;
        }

        if (isValid && validationProps?.passwordUpperCase !== undefined) {
            isValid = validationProps.passwordUpperCase;
        }

        if (isValid && validationProps?.passwordNumber !== undefined) {
            isValid = validationProps.passwordNumber;
        }

        if (isValid && validationProps?.passwordSymbol !== undefined) {
            isValid = validationProps.passwordSymbol;
        }

        return isValid;
    };

    const submitNewPassword = async () => {
        if (isNewPasswordValid() &&
            !!loginForm.model.newPassword &&
            (!!loginForm.model.resetToken || !!loginForm.model.passwordExpired)) {

            const regionIdentifier = loginForm.model.revverInstance?.name ?? loginForm.model.regionIdentifier;
            const userId = loginForm.model.userId;

            if (!regionIdentifier || !userId) {
                return;
            }

            setIsLoading(true);

            const success = await resetPasswordApi.submitNewPassword(loginForm.model.username, regionIdentifier, userId, loginForm.model.newPassword, loginForm.model.resetToken, loginForm.model.password);
            setIsLoading(false);
            if (!!success) {
                if (loginForm.model.username === '') {
                    navigate(RoutePath.Username, true);
                } else {
                    navigate(RoutePath.PasswordAndSSO);
                }
                //Should we tell people that password succeeded?
            }
            else {
                //Should we tell people that password failed?
            }
        }
    };

    const getUserSecurityPolicies = async () => {
        if (!!loginForm.model.resetToken || !!loginForm.model.passwordExpired) {

            const regionIdentifier = loginForm.model.revverInstance?.name ?? loginForm.model.regionIdentifier;
            const userId = loginForm.model.userId;

            if (!regionIdentifier || !userId) {
                return;
            }

            setIsLoading(true);
            const policies = await resetPasswordApi.getUserSecurityPolicies(loginForm.model.username, regionIdentifier, userId, loginForm.model.resetToken, loginForm.model.password);
            setIsLoading(false);
            if (!!policies) {
                setSecurityPolicies(policies);
            }
            else {
                //Toast failed to retreive security policies
            }
        }
    };

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await submitNewPassword();
        }
    };

    const getMatchIcon = () => {
        if (!loginForm.model.matchPassword) {
            return emptyIcon;
        }

        if (!validationProps?.passwordMatch) {
            return invalidIcon;
        }

        return validIcon;
    };

    useEffect(() => {
        if (!!show) {
            getUserSecurityPolicies();
        }
        else {
            setPasswordValidationProps(undefined);
        }
    }, [show]);

    useEffect(() => {
        if (!!securityPolicies) {
            setPasswordValidationProps(getPasswordValidationProps());
        }
    }, [securityPolicies,
        loginForm.model.newPassword,
        loginForm.model.matchPassword,
        validator.validateLength,
        validator.validateLowerCase,
        validator.validateUpperCase,
        validator.validateSymbol,
        validator.validateNumber]
    );

    return (
        <>
            {show &&
                <>
                    <Card.Title size='xl' bold className='mx-3 mt-4'>
                        {!!isNewUser ? 'Welcome!' : 'Create a new password'}
                    </Card.Title>
                    {loginForm.model.passwordExpired &&
                        <Card.Subtitle className='mx-3'>
                            Your password has expired.
                        </Card.Subtitle>
                    }
                    {!!isNewUser &&
                        <Card.Subtitle className='mx-3'>
                            Let's start by creating a password
                        </Card.Subtitle>
                    }
                    <Card.Body>
                        {!!isNewUser &&
                            <FormGroup className='mt-1'>
                                <Label className='m-0' for='username'>Email Address</Label>
                                <Input id='username' name='username' type='email' className='username-input-field-disabled' value={loginForm.model.username} disabled plaintext />
                            </FormGroup>
                        }
                        {!isLoading &&
                            <>

                                <FormGroup>
                                    <Label for='newPassword'>New password</Label>
                                    <Input id='newPassword' name='newPassword' type='password' value={loginForm.model.newPassword} invalid={!!loginForm.errors?.newPassword} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} autoFocus />
                                    <FormFeedback>{loginForm.errors?.newPassword}</FormFeedback>
                                </FormGroup>
                                <DisplayPasswordCompliance validationProps={validationProps} newPassword={loginForm.model.newPassword} />
                                <FormGroup className='mt-3'>
                                    <Label for='matchPassword'>Re-enter new password</Label>
                                    <Input id='matchPassword' name='matchPassword' type='password' value={loginForm.model.matchPassword} invalid={!!loginForm.errors?.matchPassword} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} />
                                </FormGroup>
                                {(!!loginForm.model.newPassword || !!loginForm.model.matchPassword) &&
                                    <>
                                        {getMatchIcon()}
                                        <Text className='validation-compliance-text'>
                                            Matches
                                        </Text>
                                    </>
                                }
                            </>
                        }
                        <Button className='mx-auto w-100 my-4' onClick={submitNewPassword} disabled={!isNewPasswordValid()}>
                            {isLoading &&
                                <Spinner size='sm' />
                            }
                            {!isLoading &&
                                <>
                                    Save password
                                </>
                            }
                        </Button>
                    </Card.Body>
                </>
            }
        </>
    );
};
