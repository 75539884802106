import React, { useEffect, useState } from 'react';
import { Button, Card, FormFeedback, FormGroup, Input, Label, Spinner } from '@efilecabinet/efc-atlantis-components';
import './ManualEmailSetting.css';
import { IUseFormObject, useForm } from '../../../../utils/useForm';
import { AuthProps } from '../../../../api/useAuthApi';
import { useEmailFormValidator } from '../../../../utils/useEmailFormValidator';
import { useOutgoingEmailApi } from '../../../../api/useOutgoingEmailApi';
import { useLoginHandler } from '../../useLoginHandler';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';
import { RoutePath } from '../../Login';
import { EmailAuthTypeEnum, EmailSettingAnonymous, EmailSettingTypeEnum } from '../OutgoingEmailTypes';

export interface ManualEmailSettingProps {
    show?: boolean;
    loginForm: IUseFormObject<AuthProps>;
}

export interface ManualEmailProps {
    username?: string;
    emailAddress?: string;
    password?: string;
    serverAddress?: string;
    serverPort?: number;
    instanceName?: string;
    userId?: number;
}

export const ManualEmailSetting = (props: ManualEmailSettingProps) => {
    const { show, loginForm } = props;

    const navigate = useLoginNavigation();

    const [separateEmailUsername, setSeparateEmailUsername] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const manualEmailForm = useForm<ManualEmailProps>({});
    const { addManualEmailSetting } = useOutgoingEmailApi();

    const { validateEmailForm } = useEmailFormValidator();

    const { login } = useLoginHandler(loginForm);

    const toggleSeparate = () => {
        if (!separateEmailUsername && !manualEmailForm.model.emailAddress) {
            manualEmailForm.setModelProperty('emailAddress', loginForm.model.username);
        }

        setSeparateEmailUsername((current) => !current);
    };

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await submit();
        }
    };

    const submit = async () => {
        setIsLoading(true);
        loginForm.clearErrors();

        let canSubmit = true;

        if (!manualEmailForm.model.username) {
            canSubmit = false;
            manualEmailForm.addError('username', 'Required');
        } else if (!validateEmailForm(manualEmailForm.model.username)) {
            canSubmit = false;
            manualEmailForm.addError('username', 'Invalid email address. Try again.');
        }

        if (separateEmailUsername && !manualEmailForm.model.emailAddress) {
            canSubmit = false;
            manualEmailForm.addError('emailAddress', 'Required');
        }

        if (!manualEmailForm.model.password) {
            canSubmit = false;
            manualEmailForm.addError('password', 'Required');
        }

        if (!manualEmailForm.model.serverAddress) {
            canSubmit = false;
            manualEmailForm.addError('serverAddress', 'Required');
        }

        if (!manualEmailForm.model.serverPort && manualEmailForm.model.serverPort !== 0) {
            canSubmit = false;
            manualEmailForm.addError('serverPort', 'Required');
        }

        if (canSubmit) {
            if (!separateEmailUsername) {
                manualEmailForm.setModelProperty('emailAddress', manualEmailForm.model.username);
            }

            let result = false;

            try {
                const emailSetting: EmailSettingAnonymous = getAnonymousEmailSetting();

                result = await addManualEmailSetting(emailSetting);

                if (!result) {
                    loginForm.addError('modelErrors', 'Unable to save manual email setting. Please verify connection information and try again.');
                } else {
                    await login();
                }
            } catch (exception: any) {
                loginForm.addError('modelErrors', exception?.response?.data);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getAnonymousEmailSetting = () => ({
        emailSetting: {
            username: manualEmailForm.model.username!,
            emailAddress: manualEmailForm.model.emailAddress ?? manualEmailForm.model.username!,
            password: manualEmailForm.model.password!,
            serverAddress: manualEmailForm.model.serverAddress!,
            serverPort: manualEmailForm.model.serverPort!,
            settingType: EmailSettingTypeEnum.SMTP,
            isAccountSetting: false,
            enableSSL: true,
            authType: EmailAuthTypeEnum.UsernameAndPassword,
        },
        anonymousEndpointAccess: {
            userName: loginForm.model.username,
            password: loginForm.model.password,
            samlToken: loginForm.model.samlToken,
        },
        instanceName: manualEmailForm.model.instanceName!,
        userId: manualEmailForm.model.userId!,
    });

    useEffect(() => {
        if (!!show) {
            manualEmailForm.setModelProperty('username', loginForm.model.username);
            manualEmailForm.setModelProperty('instanceName', loginForm.model?.revverInstance?.name);
            manualEmailForm.setModelProperty('userId', loginForm.model?.userId);
        } else {
            manualEmailForm.setModelProperty('emailAddress', undefined);
            manualEmailForm.setModelProperty('password', undefined);
            manualEmailForm.setModelProperty('serverAddress', undefined);
            manualEmailForm.setModelProperty('serverPort', undefined);
        }
    }, [show]);

    return (
        <>
            {!!show &&
                <>
                    <Card.Body>
                        <Card.Title size='xl' bold>
                            Manual connection
                        </Card.Title>
                        <FormGroup>
                            <Input id='separateEmailUsername' type='checkbox' className='checkbox' checked={separateEmailUsername} onChange={toggleSeparate} />
                            <Label for='separateEmailUsername'>Email different than username</Label>
                        </FormGroup>
                        <FormGroup className='mt-2'>
                            <Label for='username'>
                                {separateEmailUsername ? 'Username' : 'Username/Email Address'}
                            </Label>
                            <Input type={separateEmailUsername ? 'text' : 'email'} name='username' id='username' value={manualEmailForm.model.username} onChange={manualEmailForm.onPropChanged} invalid={!!manualEmailForm.errors?.username} onKeyDown={handleKeyEvent} />
                            <FormFeedback className='position-fixed'>{manualEmailForm.errors?.username}</FormFeedback>
                        </FormGroup>
                        {separateEmailUsername &&
                            <FormGroup className='mt-4'>
                                <Label for='emailAddress'>
                                    Email Address
                                </Label>
                                <Input type='email' name='emailAddress' id='emailAddress' value={manualEmailForm.model.emailAddress} onChange={manualEmailForm.onPropChanged} invalid={!!manualEmailForm.errors?.emailAddress} onKeyDown={handleKeyEvent} />
                                <FormFeedback className='position-fixed'>{manualEmailForm.errors?.emailAddress}</FormFeedback>
                            </FormGroup>
                        }
                        <FormGroup className='mt-4'>
                            <Label for='password'>
                                Password
                            </Label>
                            <Input type='password' name='password' id='password' value={manualEmailForm.model.password} onChange={manualEmailForm.onPropChanged} invalid={!!manualEmailForm.errors?.password} onKeyDown={handleKeyEvent} />
                            <FormFeedback className='position-fixed'>{manualEmailForm.errors?.password}</FormFeedback>
                        </FormGroup>
                        <FormGroup className='mt-4'>
                            <Label for='serverAddress'>
                                Email SMTP Server Address
                            </Label>
                            <Input type='textarea' name='serverAddress' id='serverAddress' value={manualEmailForm.model.serverAddress} onChange={manualEmailForm.onPropChanged} invalid={!!manualEmailForm.errors?.serverAddress} onKeyDown={handleKeyEvent} />
                            <FormFeedback className='position-fixed'>{manualEmailForm.errors?.serverAddress}</FormFeedback>
                        </FormGroup>
                        <FormGroup className='mt-4'>
                            <Label for='serverPort'>
                                Email Server Port
                            </Label>
                            <Input type='number' name='serverPort' id='serverPort' value={manualEmailForm.model.serverPort} onChange={manualEmailForm.onPropChanged} invalid={!!manualEmailForm.errors?.serverPort} onKeyDown={handleKeyEvent} />
                            <FormFeedback className='position-fixed'>{manualEmailForm.errors?.serverPort}</FormFeedback>
                        </FormGroup>
                        <Button className='w-100 mt-4 mb-1' onClick={submit}>
                            {isLoading &&
                                <Spinner size='sm' />
                            }
                            {!isLoading &&
                                <>
                                    Save and sign in
                                </>
                            }
                        </Button>
                        <Button className='w-100' emphasis='low' onClick={() => navigate(RoutePath.OutgoingEmailOptions)}>
                            Cancel
                        </Button>
                    </Card.Body>
                </>
            }
        </>
    );
};
