import { useEffect } from 'react';
import { IUseFormObject } from '../../../utils/useForm';
import './ResetPassword.css';
import { AuthProps } from '../../../api/useAuthApi';
import { RoutePath } from '../Login';
import { useLoginNavigation } from '../../../utils/useLoginNavigation';
import { Spinner } from '@efilecabinet/efc-atlantis-components/';
import { useResetPasswordApi } from '../../../api/useResetPasswordApi';
import { useParams } from 'react-router';

export interface ResetPasswordProps {
    loginForm: IUseFormObject<AuthProps>,
}

export const ResetPassword = (props: ResetPasswordProps) => {

    const navigate = useLoginNavigation();

    const { userId, resetToken } = useParams();

    const { loginForm } = props;

    const resetPasswordApi = useResetPasswordApi();

    const initialResetState = () => {
        if (!!loginForm.model.revverInstance) {
            return !!loginForm.model.passwordExpired ? RoutePath.NewPasswordInput : RoutePath.SentEmail;
        }

        return RoutePath.SentEmail;
    };

    const validateResetLink = async () => {
        if (!!loginForm.model.regionIdentifier && !!userId && !!resetToken) {
            const validResetToken = await resetPasswordApi.validateResetToken('', loginForm.model.regionIdentifier, +userId, resetToken);
            if (validResetToken) {
                loginForm.setModelProperty('userId', +userId);
                loginForm.setModelProperty('resetToken', resetToken);
                navigate(RoutePath.NewPasswordInput);
                return;
            }
        }

        loginForm.addError('modelErrors', 'Reset link has expired, please retry.');
        navigate(RoutePath.Username, true);
    };

    useEffect(() => {
        if (!!userId && !!resetToken && !!loginForm.model.regionIdentifier) {
            validateResetLink();
        }
        else {
            navigate(initialResetState());

            if (!loginForm.model.passwordExpired) {
                loginForm.setModelProperty('resetToken', undefined);
            }
        }
    }, []);

    return (
        <div className='text-center mt-5'>
            <Spinner className='region-spinner mt-5' color='primary'>
                Loading...
            </Spinner>
        </div>
    );
};
