import { useEffect } from 'react';
import { IUseFormObject } from '../../../utils/useForm';
import { AuthProps } from '../../../api/useAuthApi';
import { RoutePath } from '../Login';
import { useLoginNavigation } from '../../../utils/useLoginNavigation';
import { Spinner } from '@efilecabinet/efc-atlantis-components/';
import { useResetPasswordApi } from '../../../api/useResetPasswordApi';
import { useParams } from 'react-router';

export interface ResetPasswordProps {
    loginForm: IUseFormObject<AuthProps>,
}

export const NewUserLink = (props: ResetPasswordProps) => {

    const navigate = useLoginNavigation();

    const { userId, resetToken } = useParams();

    const { loginForm } = props;

    const resetPasswordApi = useResetPasswordApi();

    const validateNewUserPasswordLink = async () => {
        if (!!loginForm.model.regionIdentifier && !!userId && !!resetToken) {
            const validResetToken = await resetPasswordApi.validateResetToken('', loginForm.model.regionIdentifier, +userId, resetToken);
            if (validResetToken) {
                loginForm.setModelProperty('userId', +userId);
                loginForm.setModelProperty('resetToken', resetToken);
                navigate(RoutePath.NewUserInput, true);
                return;
            }
        }

        loginForm.setModelProperty('newUserExpired', true);
        navigate(RoutePath.NewUser, true);
    };

    useEffect(() => {
        if (!!userId && !!resetToken && !!loginForm.model.regionIdentifier) {
            validateNewUserPasswordLink();
        }
        else {
            navigate(RoutePath.Username);
        }
    }, []);

    return (
        <div className='text-center mt-5'>
            <Spinner className='region-spinner mt-5' color='primary'>
                Loading...
            </Spinner>
        </div>
    );
};
