import { useReducer } from 'react';

enum ModelObjActionType {
    SET_OBJECT = 'setObject',
    SET_PROP = 'setProp',
    FLIP_CHECK = 'flipCheck',
}

enum ModelErrorsActionType {
    SET_ERROR_OBJECT = 'setErrorObject',
    CLEAR_ERROR_OBJECT = 'clearErrorObject',
    ADD_ERROR = 'addError',
    CLEAR_ERROR = 'clearError',
}

interface IModelObjectAction<T> {
    type: ModelObjActionType;
    object?: T;
    propName?: string;
    value?: any;
}

interface IModelErrorsAction {
    type: ModelErrorsActionType;
    object?: any;
    propName?: string;
    value?: any;
}

export interface IUseFormObject<T> {
    model: T;
    errors: any;
    onPropChanged: ({ target: { name, value } }: any) => void;
    onCheckChanged: ({ target: { name } }: any) => void;
    setObject: (object: T) => void;
    setModelProperty: (name: string, value: any) => void;
    addError: (prop: string, value: any) => void;
    clearError: (prop: string) => void;
    clearErrors: () => void;
    handleErrors: (errModel: any) => void;
}
export function useForm<T>(initObj: T, validate?: (model: T) => any): IUseFormObject<T> {

    const errorsObjReducer = (state: any, action: IModelErrorsAction) => {
        switch (action.type) {
            case ModelErrorsActionType.SET_ERROR_OBJECT:
                return action.object;

            case ModelErrorsActionType.CLEAR_ERROR_OBJECT:
                return {};

            case ModelErrorsActionType.ADD_ERROR:
                if (!action.propName) return state;
                return { ...state, [action.propName]: action.value };

            case ModelErrorsActionType.CLEAR_ERROR:
                if (!action.propName) return state;
                return { ...state, [action.propName]: '' };

            default:
                return state;
        }
    };
    const [errorState, dispatchErrors] = useReducer(errorsObjReducer, {});

    const modelObjReducer = (state: T, action: IModelObjectAction<T>): T => {
        switch (action.type) {
            case ModelObjActionType.SET_OBJECT:
                if (!action.object) return state;
                return action.object;

            case ModelObjActionType.SET_PROP:
                if (!action.propName) return state;
                if (!!validate) {
                    const errors = validate({ ...state, [action.propName]: action.value });
                    dispatchErrors({ type: ModelErrorsActionType.SET_ERROR_OBJECT, object: errors });
                }
                return { ...state, [action.propName]: action.value };

            case ModelObjActionType.FLIP_CHECK:
                if (!action.propName) return state;
                if (!!validate) {
                    const errors = validate({ ...state, [action.propName]: !(state as any)[action.propName] });
                    dispatchErrors({ type: ModelErrorsActionType.SET_ERROR_OBJECT, object: errors });
                }
                return { ...state, [action.propName as string]: !(state as any)[action.propName] };

            default:
                return state;
        }
    };
    const [modelState, dispatchModel] = useReducer(modelObjReducer, initObj);
        
    const onPropChanged = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        clearError(name);
        dispatchModel({ type: ModelObjActionType.SET_PROP, propName: name, value });
    };

    const onCheckChanged = ({ target: { name } }: React.ChangeEvent<HTMLInputElement>) => {
        clearError(name);
        dispatchModel({ type: ModelObjActionType.FLIP_CHECK, propName: name });
    };

    const setModelProperty = (name: string, value: any) => {
        dispatchModel({ type: ModelObjActionType.SET_PROP, propName: name, value });
    };

    const setObject = (object: T) => {
        dispatchModel({ type: ModelObjActionType.SET_OBJECT, object });
    };

    const lowerFirst = (str: string) => {
        return str.charAt(0).toLowerCase() + str.slice(1);
    };

    const setErrorObject = (errorModel: any) => {
        let errObject = {};

        Object.keys(errorModel).forEach((prop: any) => {
            if (!!prop && !!errorModel[prop].errors) {
                const errors = errorModel[prop].errors.map((x: any) => x.errorMessage);
                const errorStr = errors.join('\n');
                errObject = { ...errObject, [lowerFirst(prop)]: errorStr };
            }
        });

        dispatchErrors({ type: ModelErrorsActionType.SET_ERROR_OBJECT, object: errObject });
    };

    const clearErrors = () => {
        dispatchErrors({ type: ModelErrorsActionType.CLEAR_ERROR_OBJECT });
    };

    const handleErrors = (errModel: any) => {
        setErrorObject(errModel);
    };

    const addError = (propName: string, value: any) => {
        dispatchErrors({ type: ModelErrorsActionType.ADD_ERROR, propName, value });
    };

    const clearError = (propName: string) => {
        dispatchErrors({ type: ModelErrorsActionType.CLEAR_ERROR, propName });
    };

    return { model: modelState, onPropChanged, onCheckChanged, setObject, setModelProperty, errors: errorState, addError, clearError, clearErrors, handleErrors };
}
