import Api from '../api';
import { AxiosResponse } from 'axios';

export interface Settings {
    revverInfoUrl: string;
}

export const useSettingsApi = () => {
    const getSettings = async (): Promise<Settings> => {
        const resp: AxiosResponse = await Api.get('api/settings');
        return resp.data as Settings;
    }

    return {
        getSettings,
    }
}