import { Icon, Text } from '@efilecabinet/efc-atlantis-components';
import { PasswordValidationProps } from '../useNewPasswordValidator';
import './DisplayPasswordCompliance.css';

export interface DisplayPasswordComplianceProps {
    validationProps?: PasswordValidationProps,
    newPassword?: string
}

export const DisplayPasswordCompliance = (props: DisplayPasswordComplianceProps) => {

    const { validationProps, newPassword } = props;

    const validIcon = <Icon iconName='circle-check' color='green' />;
    const invalidIcon = <Icon iconName='circle-x' color='red' />;
    const emptyIcon = <Icon iconName='circle-plus' regular />;

    const getValidationIcon = (isValid: boolean) => {
        if (!newPassword) {
            return emptyIcon;
        }

        if (isValid) {
            return validIcon;
        }

        return invalidIcon;
    };

    return (
        <div className='display-password-compliance'>
            {validationProps?.passwordLowerCase !== undefined &&
                <label>
                    {getValidationIcon(validationProps.passwordLowerCase)}
                    <Text className='validation-compliance-text'>
                        Lowercase letter (e.g. abc)
                    </Text>
                </label>
            }

            {validationProps?.passwordUpperCase !== undefined &&
                <label>
                    {getValidationIcon(validationProps.passwordUpperCase)}
                    <Text className='validation-compliance-text'>
                        Uppercase letter (e.g. ABC)
                    </Text>
                </label>
            }

            {validationProps?.passwordNumber !== undefined &&
                <label>
                    {getValidationIcon(validationProps.passwordNumber)}
                    <Text className='validation-compliance-text'>
                        Number (e.g. 1234)
                    </Text>
                </label>
            }

            {validationProps?.passwordSymbol !== undefined &&
                <label>
                    {getValidationIcon(validationProps.passwordSymbol)}
                    <Text className='validation-compliance-text'>
                        Symbol (e.g. $#!)
                    </Text>
                </label>
            }

            {!!validationProps &&
                <label>
                    {getValidationIcon(validationProps.passwordLength)}
                    <Text className='validation-compliance-text'>
                        {validationProps.validateLength} Characters
                    </Text>
                </label>
            }
        </div>
    );
};
