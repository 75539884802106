import React, { useState, useEffect, useReducer } from 'react';
import { Alert, Fade } from '@efilecabinet/efc-atlantis-components';
import { ThemeEnum } from '../components/Colors';
import parse from 'html-react-parser';

interface IStatusReducerAction {
    type: string;
    status?: string;
    color?: ThemeEnum;
    timeout?: number;
}

interface IStatusState {
    status?: string;
    color?: ThemeEnum;
    timeout?: number;
    clearStatus: () => void;
}
export const useStatusMessage = () => {

    const clearStatus = () => {
        dispatchStatus({ type: 'clearStatus' });
    };

    const setStatus = (status: string, color?: ThemeEnum, timeout?: number) => {
        dispatchStatus({ type: 'setStatus', status, color: color ?? ThemeEnum.Info, timeout: timeout ?? -1 });
    };

    const initState: IStatusState = {
        status: '',
        color: ThemeEnum.Success,
        timeout: 5000,
        clearStatus: clearStatus,
    };

    function statusReducer(state: IStatusState, action: IStatusReducerAction) {
        switch (action.type) {
            case 'setStatus':
                return {
                    ...state,
                    status: action.status,
                    color: action.color,
                    timeout: action.timeout,
                };
            case 'clearStatus':
                return {
                    ...state,
                    status: '',
                };
            default:
                return state;
        }
    }

    const [statusState, dispatchStatus] = useReducer(statusReducer, initState);

    return { statusState, setStatus, clearStatus };
};

interface IStatusProps {
    className?: string;
    statusState?: IStatusState;
}
export const Status = (props: IStatusProps) => {

    const { className, statusState, ...otherProps } = props;

    const [fadeIn, setFadeIn] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (!!statusState?.status) {

            setFadeIn(true);
            setShow(true);

            if (statusState.timeout && statusState.timeout >= 0) {
                timer = setTimeout(() => {
                    setShow(false);
                    setFadeIn(false);
                    statusState?.clearStatus();
                }, statusState?.timeout);
            }
        }
        else {
            setShow(false);
        }

        return () => {
            !!timer && clearTimeout(timer);
        };
    }, [statusState?.status]);

    return (
        <>
            <div>
                {show && (
                    <Fade in={fadeIn} active={show}>
                        <Alert color={statusState?.color || ThemeEnum.Info} className={className} {...otherProps}>
                            {parse(statusState?.status ?? '')}
                        </Alert>
                    </Fade>
                )}
            </div>
        </>
    );
};
