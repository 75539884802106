import axios, { AxiosRequestConfig } from 'axios';

const instance = axios.create();

//do other things to axios, like intercept requests and add valid bearer token, etc.
instance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem('uid');

    if (config.headers === undefined) {
        config.headers = {};
    }

    config.headers.Authorization = `Bearer ${token}`;

    config.baseURL = window.location.origin;

    return config;
});

export default instance;
