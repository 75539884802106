import { Button, Card, FormFeedback, FormGroup, Input, Label, Icon, Text, EfcLink } from '@efilecabinet/efc-atlantis-components';
import React, { useState, useEffect } from 'react';
import { AuthProps } from '../../../api/useAuthApi';
import { useEmailFormValidator } from '../../../utils/useEmailFormValidator';
import { IUseFormObject } from '../../../utils/useForm';
import { useSettingsApi } from '../../../api/useSettingsApi';
import './Username.css';

export interface UsernameProps {
    loginForm: IUseFormObject<AuthProps>;
    show: boolean;
    next: () => void;
}

export const Username = (props: UsernameProps) => {
    const { loginForm, show, next } = props;

    const [rememberMe, setRememberMe] = useState(!!localStorage.getItem('rememberMe') && localStorage.getItem('rememberMe') === 'true');
    const [revverInfoUrl, setRevverInfoUrl] = useState('');

    const { validateEmailForm } = useEmailFormValidator();
    const { getSettings } = useSettingsApi();

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    const checkEmailForm = () => {
        if (validateEmailForm(loginForm.model.username)) {
            return true;
        }

        loginForm.addError('username', 'Invalid Email Address');
        return false;
    };

    const validateEmailInput = () => {
        if (!loginForm.model.username) {
            loginForm.addError('username', 'Required.');
            return false;
        }

        return true;
    };

    const onClickNext = () => {
        if (!validateEmailInput() || !checkEmailForm()) {
            return;
        }

        loginForm.clearErrors();
        next();
    };

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (!validateEmailInput() || !checkEmailForm()) {
                return;
            }

            loginForm.clearErrors();
            next();
        }
    };

    useEffect(() => {
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));

        if (!rememberMe) {
            localStorage.removeItem('username');
            localStorage.removeItem('revverInstance');
            localStorage.removeItem('loginUserId');
        }

    }, [rememberMe]);

    useEffect(() => {
        const getAppSettings = async () => {
            const response = await getSettings();
            setRevverInfoUrl(response.revverInfoUrl);
        };
        getAppSettings();
    }, []);

    return (
        <>
            {show &&
                <>
                    <Card.Body className='mt-3 d-flex flex-column'>
                        <div>
                            <FormGroup className='remember-me-formgroup'>
                                <Input className='checkbox' id='rememberMe' name='rememberMe' type='checkbox' checked={rememberMe} onChange={handleRememberMeChange} />
                                <Label for='rememberMe'>Remember me</Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for='username'>Email Address</Label>
                                <Input id='username' name='username' type='email' dataId='txtUsername' value={loginForm.model.username} invalid={!!loginForm.errors?.username} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} autoFocus />
                                <FormFeedback className='position-fixed'>{loginForm.errors?.username}</FormFeedback>
                            </FormGroup>
                        </div>
                        <Button className='w-100 my-5' color='primary' dataId='btnNext' onClick={onClickNext}>
                            Next
                        </Button>
                    </Card.Body>
                </>
            }
        </>
    );
};
