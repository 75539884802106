import { ExternalAuthenticationAreaEnum, ExternalAuthenticationProviderEnum } from '../../AuthenticationTypes';

export enum EmailAuthTypeEnum {
    UsernameAndPassword = 0,
    OAuth = 1,
}

export enum EmailSettingTypeEnum {
    SMTP = 0,
    IMAP = 1,
}

export interface AnonymousEndpointAccess {
    userName: string;
    password: string;
    samlToken: string | undefined;
}

export interface EmailProviderAnonymous {
    externalAuthenticationArea: ExternalAuthenticationAreaEnum;
    settingType: EmailSettingTypeEnum;
    authenticationId: number;
    userId: number;
    anonymousEndpointAccess: AnonymousEndpointAccess;
    instanceName: string;
}

export interface EmailSettingAnonymous {
    emailSetting: IEmailSetting;
    anonymousEndpointAccess: AnonymousEndpointAccess;
    instanceName: string;
    userId: number;
}

export interface IEmailSetting {
    id?: number;
    accountID?: number;
    emailAddress: string;
    serverAddress: string;
    serverPort: number;
    username: string;
    password: string;
    enableSSL: boolean;
    settingType: EmailSettingTypeEnum;
    isAccountSetting: boolean;
    failureCount?: number;
    lastFailureMessage?: string;
    inFailure?: boolean;
    authType: EmailAuthTypeEnum;
    externalAuthorizationID?: number;
    externalAuthProviderType?: ExternalAuthenticationProviderEnum;
    expiration?: number;
    authToken?: string;
}

export interface UserSecurityPoliciesCompliancyResult {
    userId: number;
    isOutgoingEmailSettingPassing: boolean;
}
