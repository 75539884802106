export enum ThemeEnum {
    Primary = 'primary',
    Secondary = 'secondary',
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info',
    Light = 'light',
    Dark = 'dark',
}

export const useColors = () => {

    //From Figma
    const efcRed = '#FC4163';
    const efcOrange = '#CB411E';
    const efcTeal = '#169D96';

    //From actual "Office files"
    const pdfRed = 'red';
    const wordBlue = '#185abd';
    const excelGreen = '#1D6F42';
    const powerpointOrange = '#D04423';

    //From Utopia
    const utopiaWord = '#64B5F6';
    const utopiaWordSelected = '#2196F3';
    const utopiaExcel = '#81C784';
    const utopiaExcelSelected = '#4CAF50';
    const utopiaPowerPoint = '#D04626';
    const utopiaPowerPointSelected = '#E27E5C';
    const utopiaPDF = '#E57373';
    const utopiaPDFSelected = '#F44336';
    const utopiaMovie = '#9575CD';
    const utopiaMovieSelected = '#673AB7';
    const utopiaPhoto = '#4DB6AC';
    const utopiaPhotoSelected = '#009688';
    const utopiaAudio = '#F06292';
    const utopiaAudioSelected = '#E91E63';
    const utopiaZip = '#90A4AE';
    const utopiaZipSelected = '#607D8B';
    const utopiaDefault = '#727272';
    const utopiaDefaultSelected = '#626262';
    const utopiaHeart = '#EF5350';

    return {
        efcRed,
        pdfRed,
        efcOrange,
        efcTeal,
        wordBlue,
        excelGreen,
        powerpointOrange,

        utopiaWord: utopiaWord,
        utopiaWordSelected: utopiaWordSelected,
        utopiaExcel: utopiaExcel,
        utopiaExcelSelected: utopiaExcelSelected,
        utopiaPowerPoint: utopiaPowerPoint,
        utopiaPowerPointSelected: utopiaPowerPointSelected,
        utopiaPDF: utopiaPDF,
        utopiaPDFSelected: utopiaPDFSelected,
        utopiaMovie: utopiaMovie,
        utopiaMovieSelected: utopiaMovieSelected,
        utopiaPhoto: utopiaPhoto,
        utopiaPhotoSelected: utopiaPhotoSelected,
        utopiaAudio: utopiaAudio,
        utopiaAudioSelected: utopiaAudioSelected,
        utopiaZip: utopiaZip,
        utopiaZipSelected: utopiaZipSelected,
        utopiaDefault: utopiaDefault,
        utopiaDefaultSelected: utopiaDefaultSelected,
        utopiaHeart: utopiaHeart
    };
};