import Api from '../api';
import { AxiosResponse } from 'axios';
import { AuthProps } from './useAuthApi';

export interface SamlResponseStatus {
    samlConfigurationId?: number;
    id?: string;
    hasData: boolean;
}

export interface SamlRoleAuthentication {
    id: number;
    accountID: number;
    roleID: number;
    preAuthenticated: boolean;
    samlConfigurationID: number;
    allowFullAuthentication?: boolean;
    samlConfigurationName: string;
}

export const useSamlApi = () => {

    const beginSamlAuth = async (samlConfigurationId: number, authProps: AuthProps): Promise<SamlResponseStatus> => {
        const resp: AxiosResponse = await Api.post(`api/saml/BeginSamlAuth/${samlConfigurationId}`, authProps);
        return resp.data as SamlResponseStatus;
    };

    const checkSamlResponseStatus = async (samlToken: string, authProps: AuthProps): Promise<SamlResponseStatus> => {
        const resp: AxiosResponse = await Api.post(`api/saml/CheckSamlResponseStatus/${samlToken}`, authProps);
        return resp.data as SamlResponseStatus;
    };

    const getSamlRoleAuth = async (samlToken: string, baseUrl: string): Promise<SamlRoleAuthentication> => {
        const resp: AxiosResponse = await Api.get(`api/saml/GetSamlRoleAuth?id=${samlToken}&baseUrl=${baseUrl}`);
        return resp.data as SamlRoleAuthentication;
    };

    return {
        beginSamlAuth,
        checkSamlResponseStatus,
        getSamlRoleAuth,
    };
};
