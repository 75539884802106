import { Button, Card, FormFeedback, FormGroup, Icon, Input, Label, Spinner, Text, TooltipItem } from '@efilecabinet/efc-atlantis-components';
import React, { useEffect, useState } from 'react';
import { IUseFormObject } from '../../../utils/useForm';
import './MFA.css';
import { AuthProps, useAuthApi } from '../../../api/useAuthApi';
import { ILoginActions, useLoginHandler } from '../useLoginHandler';

export interface MFAProps {
    loginForm: IUseFormObject<AuthProps>;
    show: boolean;
}

export const MFA = (props: MFAProps) => {

    const authApi = useAuthApi();
    const { loginForm, show } = props;
    const { login, loginWithLimitedAccess } = useLoginHandler(loginForm);

    const [resendLoading, setResendLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [resendSuccessful, setResendSuccessful] = useState(false);

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (!!loginForm.model.securityCode) {
                await MFALogin();
            }
        }
    };

    const createMFACodeResendRequest = (model: AuthProps): AuthProps => {
        try {
            const applicationInfo = localStorage.getItem('applicationInfo');
            if (!!applicationInfo) {
                model.appInfo = JSON.parse(applicationInfo);
            }
        }
        catch (error) {
            // Defaults to browser if ClientID couldn't be grabbed from local storage
        }
        return model;
    };

    const resendMFACode = async () => {
        setResendLoading(true);
        setResendSuccessful(false);

        if (await authApi.resendMFACode(createMFACodeResendRequest(loginForm.model))) {
            setResendSuccessful(true);
            setTimeout(() => {
                setResendSuccessful(false);
            }, 2700);
        } else {
            loginForm.addError('modelErrors', 'Error Sending MFA Code');
            setResendSuccessful(false);
        }

        setResendLoading(false);
    };

    const getLabel = (): string => {
        if (!loginForm.model.appMFA && loginForm.model.emailMFA) {
            return 'Email code';
        }

        return 'Code';
    };

    const getTooltipMessage = (): string => {
        if (loginForm.model.appMFA && !loginForm.model.emailMFA) {
            return 'Check your email for instructions on setting up your mobile authenticator app.';
        }

        return 'Check your email for instructions on setting up your mobile authenticator app or to access the emailed sign in code.';
    };

    const MFALogin = async () => {
        setLoginLoading(true);

        let loginActions: ILoginActions = {
            onLoginFail: (error: any, handled: boolean) => {
                if (handled) {
                    loginForm.addError('securityCode', 'Invalid code. Try again or resend email');  
                }
            },
        };

        await login(loginActions);

        setLoginLoading(false);
    };

    useEffect(() => {
        if (!show) {
            setResendSuccessful(false);
            setResendLoading(false);
            setLoginLoading(false);
        }
    }, [show]);

    return (
        <>
            {show &&
                <>
                    <div className='mx-3 mt-2'>
                    {loginForm.model.appMFA && loginForm.model.emailMFA && <Text>Your account requires two-factor authentication. Open your mobile authenticator app or check your email for the log in code.</Text>}
                    {loginForm.model.appMFA && !loginForm.model.emailMFA && <Text>Your account requires two-factor authentication. Open your mobile authenticator app for the sign in code.</Text>}
                        {!loginForm.model.appMFA && loginForm.model.emailMFA &&
                            <>
                                <Text>Your account requires two-factor authentication. The sign in code was sent to:</Text>
                                <FormGroup>
                                    <Input id='username' name='username' type='email' className='username-input-field-disabled' value={loginForm.model.username} disabled plaintext />
                                </FormGroup>
                            </>
                        }
                    </div>
                    <Card.Body>
                        <FormGroup className='position-relative'>
                            {loginForm.model.emailMFA && 
                                <Button emphasis='low' color='primary' className='button-no-hover-color input-context-button' onClick={resendMFACode}>
                                    {resendSuccessful && <><Icon className='my-auto' iconName='circle-check' /> Sent</>}
                                    {!resendSuccessful && resendLoading && <Spinner size='sm' className='my-auto me-2' />}
                                    {!resendSuccessful && <>Resend email</>}
                                </Button>
                            }
                            {loginForm.model.appMFA && !loginForm.model.emailMFA &&
                                <Button emphasis='low' color='primary' className='button-no-hover-color input-context-button' onClick={resendMFACode}>
                                    {resendSuccessful && <><Icon className='my-auto' iconName='circle-check' /> Sent</>}
                                    {!resendSuccessful && resendLoading && <Spinner size='sm' className='my-auto me-2' />}
                                    {!resendSuccessful && <>Resend app instruction email</> }
                                </Button>
                            }
                            <Label for='securityCode'>
                                {getLabel()}
                                {loginForm.model.appMFA &&
                                    <TooltipItem innerClassName='security-code-icon my-auto' id='security-code-tooltip' message={getTooltipMessage()}>
                                        <Icon iconName='circle-question' color='primary' light />
                                    </TooltipItem>
                                }
                            </Label>
                            <Input id='securityCode' name='securityCode' type='text' value={loginForm.model.securityCode} invalid={!!loginForm.errors?.securityCode} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} autoFocus />
                            <FormFeedback className='position-fixed'>{loginForm.errors?.securityCode}</FormFeedback>
                        </FormGroup>
                        <Button className='mx-auto w-100 mt-5' onClick={MFALogin} disabled={!loginForm.model.securityCode}>
                            {loginLoading &&
                                <Spinner size='sm' className='mt-1' />
                            }
                            {!loginLoading &&
                                <>
                                    Sign in
                                </>
                            }
                        </Button>
                        {loginForm.model.limitedAccess !== undefined &&
                            <Button emphasis='low' color='primary' className='w-100 my-3' onClick={loginWithLimitedAccess}>
                                Sign in with limited access
                                <TooltipItem id='limited-access-mfa' innerClassName='my-auto' position='right' message='After you sign in, you will have limited access to any account that has additional sign in requirements that you haven’t met yet. To get full access, you can switch accounts after sign in and complete all sign in requirements.'>
                                    <Icon className='mx-2 my-auto' iconName='circle-question' light />
                                </TooltipItem>
                            </Button>
                        }
                    </Card.Body>
                </>
            }
        </>
    );
};
