import { Button, Card, FormFeedback, FormGroup, Input, Label, Icon, Spinner, Collapse, Text } from '@efilecabinet/efc-atlantis-components';
import React, { useEffect, useState } from 'react';
import { IUseFormObject } from '../../../../utils/useForm';
import { OptionsTile } from '../../../../components/OptionsTile';
import './SentEmail.css';
import { AuthProps } from '../../../../api/useAuthApi';
import { useResetPasswordApi, ResetPasswordTypeEnum } from '../../../../api/useResetPasswordApi';
import GmailSrc from '../../../../assets/images/Gmail.png';
import OutlookSrc from '../../../../assets/images/Outlook.png';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';
import { RoutePath } from '../../Login';

export interface SentEmailProps {
    loginForm: IUseFormObject<AuthProps>,
    show: boolean,
    isNewUser?: boolean;
}

export const SentEmail = (props: SentEmailProps) => {

    const { loginForm, show, isNewUser } = props;

    const navigate = useLoginNavigation();

    const [showTokenInput, setShowTokenInput] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [resendSuccessful, setResendSuccessful] = useState<boolean | undefined>();

    const resetPasswordApi = useResetPasswordApi();

    const toggleShowCode = () => {
        setShowTokenInput((currentState) => !currentState);
    }

    const validateResetToken = async (username: string, instanceName: string, userId: number, resetToken: string) => {
        const isValidToken = await resetPasswordApi.validateResetToken(username, instanceName, userId, resetToken);
        return !!isValidToken;
    }

    const onSubmit = async () => {
        if (!!loginForm.model.resetToken && !!loginForm.model.userId && !!loginForm.model.username && !!loginForm.model.revverInstance) {
            if (await validateResetToken(loginForm.model.username, loginForm.model.revverInstance.name, loginForm.model.userId, loginForm.model.resetToken)) {
                navigate(RoutePath.NewPasswordInput);
            }
        }
    }

    const canSubmit = () => {
        return !!loginForm.model.resetToken &&
            !!loginForm.model.userId &&
            !!loginForm.model.username &&
            !!loginForm.model.revverInstance &&
            loginForm.model.resetToken.length === 6;
    };

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await onSubmit();
        }
    };

    const resendResetCode = async () => {
        if (!!loginForm.model.username && !!loginForm.model.revverInstance) {
            setResendLoading(true);
            try {
                await resetPasswordApi.sendResetRequest(loginForm.model.username, loginForm.model.revverInstance.name, ResetPasswordTypeEnum.Email, isNewUser);
                setResendSuccessful(true);
                setTimeout(() => {
                    setResendSuccessful(undefined);
                }, 2500);
            }
            catch (error: any) {
                setResendSuccessful(undefined);
                let errorData = error?.response?.data;
                if (!!errorData) {
                    loginForm.addError('modelErrors', errorData);
                }
                else {
                    loginForm.addError('modelErrors', error);
                }
            }
            setResendLoading(false);
        }
        else {
            setResendLoading(true);
            setTimeout(() => {
                setResendLoading(false);
                setResendSuccessful(true);
                setTimeout(() => {
                    setResendSuccessful(undefined);
                }, 2500);
            }, 1000);
        }
    };

    const goToText = () => {
        if (!!loginForm.model.username && !!loginForm.model.revverInstance) {
            resetPasswordApi.sendResetRequest(loginForm.model.username, loginForm.model.revverInstance.name, ResetPasswordTypeEnum.TextMessage);
            navigate(RoutePath.SentText);
        }
    };

    const openExternalEmailTab = (url: string) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        if (!show) {
            setShowTokenInput(false);
        }
    }, [show]);

    return (
        <>
            {show &&
                <>
                    <Card.Title size='xl' bold className='mt-4 mx-auto'>
                        <Icon color='primary' className='sent-confirm-icon' iconName='circle-check' />
                        Reset password email sent
                    </Card.Title>
                    <div className='text-center my-3 username-input-field-disabled'>
                        {loginForm.model.username}
                    </div>
                    <Card.Body>
                        <div className='external-email-link-container'>
                            <Button className='h-100 ms-3' onClick={() => openExternalEmailTab('https://www.gmail.com')} emphasis='low'>
                                <img height='30' src={GmailSrc} alt="Gmail" />
                                <div>
                                    Open Gmail
                                    <Icon iconName='external-link' className='my-auto mx-2' />
                                </div>
                            </Button>
                            <Button className='h-100 me-3' onClick={() => openExternalEmailTab('https://outlook.office.com')} emphasis='low'>
                                <img height='30' src={OutlookSrc} alt="Outlook" />
                                <div>
                                    Open Outlook
                                    <Icon iconName='external-link' className='my-auto mx-2' />
                                </div>
                            </Button>
                        </div>
                        <Button className='w-100 my-2 mb-3 resend-email-button' onClick={resendResetCode}>
                            {resendSuccessful &&
                                <Icon className='my-auto' iconName='circle-check' />
                            }
                            {resendLoading &&
                                <Spinner size='sm' className='my-auto' />
                            }
                            {!resendLoading && resendSuccessful === undefined &&
                                <>
                                    Resend email
                                </>
                            }
                        </Button>
                        {!isNewUser &&
                            <OptionsTile onClick={toggleShowCode} selected={false}>
                                Trouble with the password reset email?
                            </OptionsTile>
                        }
                        {!isNewUser &&
                            <OptionsTile onClick={goToText} selected={false}>
                                Send text instead
                            </OptionsTile>
                        }
                        <Collapse isOpen={showTokenInput}>
                            <div className='mt-1'>
                                <Text>
                                    We emailed you a link to reset your password. If the link in the email isn’t working, you can use the password reset code from the email instead.
                                </Text>
                                <FormGroup className='mt-4'>
                                    <Label>
                                        Reset password code
                                    </Label>
                                    <Input id='resetToken' name='resetToken' type='text' value={loginForm.model.resetToken} invalid={!!loginForm.errors?.resetToken} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} autoFocus />
                                    <FormFeedback>{loginForm.errors?.resetToken}</FormFeedback>
                                </FormGroup>
                            </div>
                        </Collapse>
                        {showTokenInput &&
                            <Button className='w-100 mt-4 mb-3' onClick={onSubmit} emphasis='med' disabled={!canSubmit()}>
                                Submit code
                            </Button>
                        }
                    </Card.Body>
                </>
            }
        </>
    );
};
