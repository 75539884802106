import { useSearchParams } from "react-router-dom";
import { EnvironmentDetails, useAuthApi } from "../../api/useAuthApi";
import { AuthFlowTypeEnum } from "./Login";

export const useLoginUtilities = () => {

    const { isAccessTokenValidAsync, getRedirectLocation } = useAuthApi();

    const [searchParams, setSearchParams] = useSearchParams();

    const hasSalesforceInQuery = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const isSalesforce = queryParameters.get('salesforce');
        return !!isSalesforce;
    };

    const clearUnneededParams = () => {
        searchParams.delete('token');
        searchParams.delete('region');
        setSearchParams(searchParams);
    };

    const additionalParams = () => {
        const queryParameters = new URLSearchParams(window.location.search);
        const redirect = queryParameters.get('redirect');
        const goTo = queryParameters.get('goTo');
        const email = queryParameters.get('email');

        if (!!goTo) {
            clearUnneededParams();
            return window.location.search;
        }

        if (!!redirect) {
            clearUnneededParams();
            return window.location.search;
        }

        if (hasSalesforceInQuery() && !!email) {
            return `?redirect=/salesforce&email=${email}&noredirect=true`;
        }

        return '';
    };

    const redirectToRubexExperience = async (environmentDetails: EnvironmentDetails) => {
        let location = await getRedirectLocation(environmentDetails);
        window.location.href = location + additionalParams();
    };

    const continueToRevverIfAuthenticated = (setAuthFlowType: Function) => {
        if (!!localStorage.getItem('uid') &&
            !!localStorage.getItem('loginEnvironmentDetails')) {

            setAuthFlowType(AuthFlowTypeEnum.SkipIfAuthenticated);

            const accessToken = localStorage.getItem('uid') ?? '';
            const environmentDetails = JSON.parse(localStorage.getItem('loginEnvironmentDetails') ?? '');

            isAccessTokenValidAsync({ accessToken, environmentDetails })
                .then(response => {
                    if (response.isAuthenticated) {
                        redirectToRubexExperience(environmentDetails);
                    } else {
                        setAuthFlowType(AuthFlowTypeEnum.Login);
                    }
                }).catch(() => {
                    setAuthFlowType(AuthFlowTypeEnum.Login);
                });
        }
    }

    return {
        continueToRevverIfAuthenticated,
        hasSalesforceInQuery,
        redirectToRubexExperience,
    }
};