import { Card, FormGroup, Input, Label, Spinner } from '@efilecabinet/efc-atlantis-components';
import React, { useEffect, useState } from 'react';
import { AuthProps, useAuthApi, UserRegion } from '../../../api/useAuthApi';
import { OptionsTile } from '../../../components/OptionsTile';
import { IUseFormObject } from '../../../utils/useForm';
import './Region.css';

export interface RegionProps {
    loginForm: IUseFormObject<AuthProps>,
    show: boolean,
    next: () => void,
}

export const Region = (props: RegionProps) => {

    //const { t } = useTranslation("Region");
    const { loginForm, show, next } = props;

    const [userRegions, setAvailableRegions] = useState<UserRegion[]>([])

    const auth = useAuthApi();

    const getRegions = async () => {
        const availableInstances = await auth.getAvailableInstances(loginForm.model);
        loginForm.setModelProperty('hasMultipleRegions', false);

        if (availableInstances === undefined || availableInstances.length === 0) {
            // This username doesn't exist in any region, but we're not going to tell them that till they try to put in a pw
            next();
            return;
        }

        if (availableInstances.length === 1) {
            loginForm.setModelProperty('revverInstance', availableInstances[0].key);
            loginForm.setModelProperty('userId', availableInstances[0].value);

            handleRememberMe(availableInstances[0]);

            next();
            return;
        } else if (!!loginForm.model.regionIdentifier && !!availableInstances.find(i => i.key.name === loginForm.model.regionIdentifier)) {
            const index = availableInstances.findIndex(i => i.key.name === loginForm.model.regionIdentifier);
            const region = availableInstances[index];

            loginForm.setModelProperty('revverInstance', region.key);
            loginForm.setModelProperty('userId', region.value);

            handleRememberMe(region);

            next();
            return;
        }

        loginForm.setModelProperty('hasMultipleRegions', true);

        setAvailableRegions(availableInstances);
    }

    const handleRememberMe = (userRegion: UserRegion) => {
        let rememberMe: boolean = !!localStorage.getItem('rememberMe') && localStorage.getItem('rememberMe') === 'true';
        if (rememberMe) {
            localStorage.setItem('revverInstance', JSON.stringify(userRegion.key));
        }
    };

    const handleChange = (targetName: string) => {
        const targetRegion = userRegions.find(x => x.key.name === targetName);

        if (!!targetRegion) {
            loginForm.setModelProperty('revverInstance', targetRegion.key);
            loginForm.setModelProperty('userId', targetRegion.value);

            handleRememberMe(targetRegion);

            next();
        }
        else {
            console.log(`Target region not found: ${targetName}`);
        }
    }

    useEffect(() => {
        if (!!show) {
            getRegions();
        }
        else {
            setAvailableRegions([]);
        }
    }, [show])

    return (
        <>
            {show &&
                <>
                    <Card.Body>
                        <FormGroup>
                            <Label className='m-0' for='username'>Email Address</Label>
                            <Input id='username' name='username' type='email' className='username-input-field-disabled' value={loginForm.model.username} disabled plaintext />
                        </FormGroup>
                        {userRegions.length > 0 &&
                            <FormGroup>
                                <Label for='region'>Select a product</Label>
                                {userRegions.map((value, index) =>
                                    <OptionsTile key={index} onClick={() => handleChange(value.key.name)} selected={loginForm.model.revverInstance?.name === value.key.name}>
                                        {value.key.name}
                                    </OptionsTile>
                                )}
                            </FormGroup>
                        }
                        {userRegions.length === 0 &&
                            <div className='text-center mt-5'>
                                <Spinner className='region-spinner mt-5' color='primary'>
                                    Loading...
                                </Spinner>
                            </div>
                        }
                    </Card.Body>
                </>
            }
        </>
    );
};
