export const useEmailFormValidator = () => {
    function validateEmailForm(email: string) {
        //Note - does not match international characters https://goo.gl/bO1PSp
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email.toLowerCase());
    }

    return {
        validateEmailForm
    }
};
