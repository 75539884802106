import React, { ReactNode } from 'react';
import './OptionsTile.css';

export interface OptionsTileProps {
    children: ReactNode;
    onClick: (data?: any) => void;
    selected?: boolean;
    emphasis?: string;
}

export const OptionsTile = (props: OptionsTileProps) => {
    const { children, onClick, selected, emphasis } = props;

    return (
        <>
            <div className={`w-100 provider-card cursor-pointer ${!!selected && 'provider-card-selected'} ${emphasis}-emphasis`} onClick={onClick}>
                {children}
            </div>
        </>
    );
};
