import { Text, RevverIcon, EfcLink } from '@efilecabinet/efc-atlantis-components';
import { IUseFormObject } from '../../../../utils/useForm';
import { AuthProps } from '../../../../api/useAuthApi';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';
import { RoutePath } from '../../Login';
import { Col, Container, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import ExpiredImg from '../../../../assets/images/Expired.png';
import NoUsesRemainingImg from '../../../../assets/images/NoUsesLeft.png';
import './Exception.css';

export interface ExceptionProps {
    loginForm: IUseFormObject<AuthProps>;
}

enum ExceptionType {
    NoUsesRemaining,
    Expired,
    UploadLinkMissing
}

export const Exception = (props: ExceptionProps) => {
    const { loginForm } = props;
    const navigate = useLoginNavigation();

    const [exceptionType, setExceptionType] = useState<ExceptionType>();
    const [exceptionMessage, setExceptionMessage] = useState<string>('');
    const [exceptionTitle, setExceptionTitle] = useState<string>('');
    const [messageWidth, setMessageWidth] = useState<string>('');
    const [imgSrc, setImgSrc] = useState<string>('');

    const currentYear = new Date().getFullYear();

    const handleNavigateToLogin = () => {
        navigate(RoutePath.Username);
        loginForm.setModelProperty('accessLinkNoUsesRemaining', false);
        loginForm.setModelProperty('accessLinkExpired', false);
        loginForm.setModelProperty('uploadLinkMissing', false);
    };

    useEffect(() => {
        if (!!loginForm.model.accessLinkNoUsesRemaining) {
            setExceptionType(ExceptionType.NoUsesRemaining);
        } else if (!!loginForm.model.accessLinkExpired) {
            setExceptionType(ExceptionType.Expired);
        }
        else if (!!loginForm.model.uploadLinkMissing) {
            setExceptionType(ExceptionType.UploadLinkMissing);
        }
    }, [loginForm.model.accessLinkNoUsesRemaining, loginForm.model.accessLinkExpired, loginForm.model.uploadLinkMissing]);

    useEffect(() => {
        if (exceptionType === ExceptionType.NoUsesRemaining) {
            setExceptionTitle('This link reached its maximum use limit.');
            setExceptionMessage('Human potential is limitless, but this link is not. It was created with a limit to how many times it could be accessed. Reach out to the sender to receive a new link.');
            setImgSrc(NoUsesRemainingImg);
            setMessageWidth('width-600');
        } else if (exceptionType === ExceptionType.Expired) {
            setExceptionTitle('This link expired.');
            setExceptionMessage('All good things must come to an end, including this link. Reach out to the sender to receive a new link.');
            setImgSrc(ExpiredImg);
            setMessageWidth('width-400');
        }
        else if (exceptionType == ExceptionType.UploadLinkMissing) {
            setExceptionTitle('This link is no longer valid.');
            setExceptionMessage('All good things must come to an end, including this link. Reach out to the sender to receive a new link.');
            setImgSrc(ExpiredImg);
            setMessageWidth('width-400');
        }
    }, [exceptionType]);

    return (
        <div className='page-wrapper d-flex flex-column justify-content-between vh-100'>
            <EfcLink onClick={handleNavigateToLogin} className='mb-5'>
                <div className='logo-container'>
                    <RevverIcon color='#7514BD' className='p-4' />
                </div>
            </EfcLink>
            <Container className='mt-5'>
                <Row className='d-flex justify-content-center align-items-center'>
                    <Col xs='8' sm='6' lg='3'>
                        <img src={imgSrc} alt='Exception' className='exception-img w-100' />
                    </Col>
                    <Col sm='10' md='6' lg='5' className='mt-3 mt-md-0'>
                        <div className={`${messageWidth} mx-auto px-4 px-md-0`}>
                            <Text tag='h2' semibold className='text-center text-md-start'>
                                {exceptionTitle}
                            </Text>
                            <Text semibold>{exceptionMessage}</Text>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className='flex-grow-1'></div>
            <EfcLink className='text-center text-primary fw-bold mb-4' onClick={handleNavigateToLogin}>
                Sign in to Revver
            </EfcLink>
            <Text className='footer text-center p-3 mt-0'>Copyright {currentYear} © Revver. All rights reserved.</Text>
        </div>
    );
};
