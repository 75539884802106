import { useEffect, useState } from 'react';
import { EfcCallToActionInfo, EfcLink, Icon, Modal } from '@efilecabinet/efc-atlantis-components';
import { IUseFormObject } from '../../../utils/useForm';
import { AuthProps } from '../../../api/useAuthApi';
import { useLoginHandler } from '../useLoginHandler';
import { useIcons } from '../../../components/Icons';
import './EulaModal.css';

export interface EulaModalProps {
    isOpen: boolean;
    toggle: () => void;
    loginForm: IUseFormObject<AuthProps>;
}

export const EulaModal = ({ isOpen, toggle, loginForm }: EulaModalProps) => {
    const { login } = useLoginHandler(loginForm);
    const { spinnerIcon } = useIcons();

    const [showSpinner, setShowSpinner] = useState(false);
    const [ctas, setCtas] = useState<EfcCallToActionInfo[]>([]);

    const updateModel = () => loginForm.setModelProperty('agreeToEula', true);

    useEffect(() => {
        setCtas([
            { text: 'Cancel', emphasis: 'med', onClick: toggle, dataId: 'cancelEula' },
            { text: 'Agree', emphasis: 'high', color: 'primary', onClick: updateModel, dataId: 'agreeToEula' },
        ]);
    }, []);

    useEffect(() => {
        const updatedCtas = [...ctas];

        const ctaIndex = updatedCtas.findIndex((cta) => cta.text === 'Agree');

        if (ctaIndex !== -1) {
            if (!!showSpinner) {
                updatedCtas[ctaIndex].icon = spinnerIcon;
            } else {
                delete updatedCtas[ctaIndex].icon;
            }

            setCtas(updatedCtas);
        }
    }, [showSpinner]);

    useEffect(() => {
        const accept = async () => {
            setShowSpinner(true);
            await login();
            setShowSpinner(false);
            toggle();
        };

        if (!!loginForm.model.agreeToEula) {
            accept();
        }
    }, [loginForm.model.agreeToEula]);

    return (
        <Modal backdrop='static' title='End User License Agreement' toggle={toggle} isOpen={isOpen} ctas={ctas}>
            <Modal.Body>
                <div className='fw-bold'>
                    Before you can access Revver, please read and agree to the
                    <EfcLink dataId='eulaLink' newWindow href='https://www.revverdocs.com/terms-of-use/' className='text-primary d-block'>{' '}
                        Revver End User License Agreement <Icon iconName='external-link' className='icon-alignment' />
                    </EfcLink>
                </div>
            </Modal.Body>
        </Modal>
    );
};
