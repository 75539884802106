import Api from '../api';
import { AxiosResponse } from 'axios';
import { ApplicationInfo, AuthenticationTypeEnum, IAuthUser, IUser, RevverInstance } from '../auth/AuthenticationTypes';
import { SamlRoleAuthentication } from './useSamlApi';

export interface EnvironmentDetails {
    cname?: string;
    isSalesforce: boolean;
    newExperience: string | null;
    regionIdentifier: string | null;
    revverInstance?: RevverInstance;
    userId?: number;
}

export interface AuthProps extends EnvironmentDetails {
    username: string;
    password: string;
    limitedAccess?: boolean;
    requiresEmailSetting: boolean;
    authenticationRequestType?: AuthenticationTypeEnum;
    resetToken?: string;
    newPassword?: string;
    matchPassword?: string;
    requiresMFA: boolean;
    emailMFA: boolean;
    appMFA: boolean;
    securityCode?: string;
    samlToken?: string;
    hasMultipleRegions: boolean;
    linked: string | null;
    passwordExpired: boolean;
    inactivityTimeout: string | null;
    samlRoleAuthentication?: SamlRoleAuthentication;
    samlRoleAuthorizeCode?: string;
    appInfo?: ApplicationInfo;
    newUserExpired: boolean;
    accessLinkToken: string | null;
    accessLinkNeedsInfo: boolean;
    accessLinkNoUsesRemaining: boolean;
    accessLinkExpired: boolean;
    uploadLinkMissing: boolean;
    anonymousUserFirstName?: string;
    anonymousUserLastName?: string;
    anonymousUserEmail?: string;
    agreeToEula: boolean;
    requiresEulaAgreement?: boolean;
    skipEulaCheck: boolean;
}

export interface UserRegion {
    key: RevverInstance;
    value: number;
}

export interface IdentityProvider {
    name: string;
    samlConfigurationId: number;
}

export interface ValidateOneTimePasscodeResponse {
    isValid: boolean;
}

export interface IsAccessTokenValidRequest {
    environmentDetails: EnvironmentDetails;
    accessToken: string;
}

export interface IsAccessTokenValidResponse {
    isAuthenticated: boolean;
}

export const useAuthApi = () => {

    const getAvailableInstances = async (authProps: AuthProps): Promise<UserRegion[]> => {
        const resp: AxiosResponse = await Api.get('api/authentication/GetAvailableInstances?username=' + encodeURIComponent(authProps.username));
        return resp.data as UserRegion[];
    };

    const getRedirectLocation = async (environmentDetails: EnvironmentDetails): Promise<string> => {
        const resp: AxiosResponse = await Api.post('api/authentication/GetRedirectLocation', environmentDetails);
        return resp.data as string;
    };

    const getSSOProviders = async (authProps: AuthProps): Promise<IdentityProvider[]> => {
        const resp: AxiosResponse = await Api.post('api/authentication/GetSSOProviders', authProps);
        return resp.data as IdentityProvider[];
    };

    const validateSSOPasscode = async (authProps: AuthProps): Promise<ValidateOneTimePasscodeResponse> => {
        const resp: AxiosResponse = await Api.post('api/authentication/ValidateSSOPasscode', authProps);
        return resp.data as ValidateOneTimePasscodeResponse;
    };

    const addSSOAuthWithoutFullUser = async (authProps: AuthProps, dontAskAgain: boolean) => {
        await Api.post(`api/authentication/AddSSOAuthWithoutFullUser?dontAskAgain=${dontAskAgain}`, authProps);
    };

    const sendSSOPasscode = async (authProps: AuthProps) => {
        await Api.post('api/authentication/SendSSOPasscode', authProps);
    };

    const login = async (authProps: AuthProps): Promise<IAuthUser> => {
        const resp: AxiosResponse = await Api.post('api/authentication/Login', authProps);
        return resp.data as IAuthUser;
    };

    const resendMFACode = async (authProps: AuthProps): Promise<boolean> => {
        const resp: AxiosResponse = await Api.post('api/authentication/ResendMFACode', authProps);
        return !!resp;
    };

    const isAccessTokenValidAsync = async (request: IsAccessTokenValidRequest): Promise<IsAccessTokenValidResponse> => {
        const resp: AxiosResponse = await Api.post('api/authentication/IsAccessTokenIsValid', request);
        return resp.data as IsAccessTokenValidResponse;
    };

    return {
        getAvailableInstances,
        getRedirectLocation,
        getSSOProviders,
        validateSSOPasscode,
        addSSOAuthWithoutFullUser,
        sendSSOPasscode,
        login,
        resendMFACode,
        isAccessTokenValidAsync,
    };
};
