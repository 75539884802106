export function CreateCenteredPopup(url: string, title: string, width: number, height: number): Window | null {
    // Fixes dual-screen position                             Most browsers      Firefox
    let dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    let dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    let windowWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    let windowHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    let systemZoom = windowWidth / window.screen.availWidth;
    let left = (windowWidth - width) / 2 / systemZoom + dualScreenLeft;
    let top = (windowHeight - height) / 2 / systemZoom + dualScreenTop;
    let newWindow = window.open(url, title, `scrollbars=yes, width=${width / systemZoom}, height=${height / systemZoom}, top=${top}, left=${left}`);

    if (!!newWindow) {
        newWindow.focus();
    }

    return newWindow;
}