import Api from '../api';
import { AxiosResponse } from 'axios';
import { ExternalOAuth, IUser } from '../auth/AuthenticationTypes';
import { EmailProviderAnonymous, EmailSettingAnonymous } from '../auth/Login/OutgoingEmail/OutgoingEmailTypes';

export const useOutgoingEmailApi = () => {
    const addManualEmailSetting = async (emailSetting: EmailSettingAnonymous): Promise<boolean> => {
        const resp: AxiosResponse = await Api.post('api/OutgoingEmail/AddManualEmailSetting', emailSetting);
        return !!resp.data;
    };

    const getEmailProviders = async (anonymousAccess: EmailProviderAnonymous): Promise<ExternalOAuth[]> => {
        const resp: AxiosResponse = await Api.post('api/OutgoingEmail/GetEmailProviders', anonymousAccess);
        return resp.data as ExternalOAuth[];
    };

    const getEmailSettings = async (username: string, instanceName?: string): Promise<IUser> => {
        let userName = encodeURIComponent(username);
        const resp: AxiosResponse = await Api.get(`api/OutgoingEmail/GetEmailSettings?instanceName=${instanceName}&username=${encodeURIComponent(userName)}`);
        return resp.data as IUser;
    };

    const validateEmailSettings = async (userId: number, instanceName?: string) => {
        const resp: AxiosResponse = await Api.get(`api/OutgoingEmail/ValidateEmailSetting?instanceName=${instanceName}&userId=${userId}`);
        return resp.data;
    };

    return {
        addManualEmailSetting,
        getEmailProviders,
        getEmailSettings,
        validateEmailSettings,
    };
};
