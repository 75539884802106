import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { EfcIconProps } from '@efilecabinet/efc-atlantis-components';
import { NodeType } from '../../utils/CommonTypes';
import { useColors } from '../Colors';

export const useIcons = () => {
    const loadIcons = () => {
        library.add(fas, far, faCircleQuestion);
    };

    const colors = useColors();

    const homeIcon: EfcIconProps = { iconName: 'house' };
    const documentsIcon: EfcIconProps = { iconName: 'folder' };
    const documentRequestsIcon: EfcIconProps = { iconName: 'folder-plus' };
    const eSignatureIcon: EfcIconProps = { iconName: 'file-signature', regular: true };
    const formsIcon: EfcIconProps = { iconName: 'clipboard-list', regular: true };
    const workflowIcon: EfcIconProps = { iconName: 'wand-magic-sparkles' };
    const reportsIcon: EfcIconProps = { iconName: 'chart-line-up' };
    const allFeaturesIcon: EfcIconProps = { iconName: 'grid' };
    const favoritesIcon: EfcIconProps = { iconName: 'heart' };
    const portfolioIcon: EfcIconProps = { iconName: 'folder-heart' };
    const checkedOutIcon: EfcIconProps = { iconName: 'lock' };
    const lockIcon: EfcIconProps = { iconName: 'lock' };
    const unlockIcon: EfcIconProps = { iconName: 'unlock' };
    const checkmarkIcon: EfcIconProps = { iconName: 'check' };
    const recycleBinIcon: EfcIconProps = { iconName: 'trash-can' };
    const deleteIcon: EfcIconProps = { iconName: 'trash-can' };
    const restoreRecycleBinIcon: EfcIconProps = { iconName: 'rotate-right' };
    const templatesIcon: EfcIconProps = { iconName: 'sitemap' };
    const emailImportsIcon: EfcIconProps = { iconName: 'envelope', regular: true };
    const profilesIcon: EfcIconProps = { iconName: 'code' };
    const governanceIcon: EfcIconProps = { iconName: 'shield' };
    const usersIcon: EfcIconProps = { iconName: 'user' };
    const groupsIcon: EfcIconProps = { iconName: 'users' };
    const accessLinksIcon: EfcIconProps = { iconName: 'link' };
    const auditLogsIcon: EfcIconProps = { iconName: 'clock-rotate-left' };
    const wrenchIcon: EfcIconProps = { iconName: 'wrench' };
    const searchIcon: EfcIconProps = { iconName: 'search' };
    const settingsIcon: EfcIconProps = { iconName: 'gear' };
    const securityPoliciesIcon: EfcIconProps = { iconName: 'shield' };
    const salesforceIcon: EfcIconProps = { iconName: 'cloud', regular: true };
    const newFolderIcon: EfcIconProps = { iconName: 'folder' };
    const newFileIcon: EfcIconProps = { iconName: 'file', regular: true };
    const workIcon: EfcIconProps = { iconName: 'briefcase' };
    const wavepulseIcon: EfcIconProps = { iconName: 'wave-pulse', regular: true };

    const newIcon: EfcIconProps = { iconName: 'circle-plus', regular: true };
    const caretDown: EfcIconProps = { iconName: 'angle-down', regular: true };
    const caretUp: EfcIconProps = { iconName: 'angle-up', regular: true };
    const circleUser: EfcIconProps = { iconName: 'circle-user', regular: true };
    const lightbulbIcon: EfcIconProps = { iconName: 'lightbulb', regular: true };
    const partyHornIcon: EfcIconProps = { iconName: 'party-horn' };
    const taskIcon: EfcIconProps = { iconName: 'circle-check' };
    const backArrowIcon: EfcIconProps = { iconName: 'arrow-left' }

    //File Icons
    const cabinetIcon: EfcIconProps = { iconName: 'folder' };
    const drawerIcon: EfcIconProps = { iconName: 'folder' };
    const fileIcon: EfcIconProps = { iconName: 'file', regular: true };
    const pdfIcon: EfcIconProps = { iconName: 'file-pdf', regular: true, color: colors.pdfRed };
    const docIcon: EfcIconProps = { iconName: 'file-word', regular: true, color: colors.wordBlue };
    const excelIcon: EfcIconProps = { iconName: 'file-excel', regular: true, color: colors.excelGreen };
    const powerpointIcon: EfcIconProps = { iconName: 'file-powerpoint', regular: true, color: colors.powerpointOrange };
    const csvIcon: EfcIconProps = { iconName: 'file-csv', regular: true };
    const textFileIcon: EfcIconProps = { iconName: 'file-lines', regular: true };
    const codeFileIcon: EfcIconProps = { iconName: 'file-code', regular: true };
    const fileLockIcon: EfcIconProps = { iconName: 'file-lock', regular: true };
    const zipFileIcon: EfcIconProps = { iconName: 'file-zipper', regular: true, color: colors.utopiaZip };

    const movieIcon: EfcIconProps = { iconName: 'file-video', regular: true, color: colors.utopiaMovie };
    const imageIcon: EfcIconProps = { iconName: 'file-image', regular: true, color: colors.utopiaPhoto };
    const audioIcon: EfcIconProps = { iconName: 'file-audio', regular: true, color: colors.utopiaAudio };
    const emailIcon: EfcIconProps = { iconName: 'file-envelope', regular: true };
    const spreadsheetIcon: EfcIconProps = { iconName: 'file-spreadsheet', regular: true, color: colors.utopiaExcel };
    const invoiceIcon: EfcIconProps = { iconName: 'file-invoice', regular: true, color: colors.utopiaPowerPoint };

    const spinnerIcon: EfcIconProps = { iconName: 'circle-notch', regular: true, spin: true };

    const getItemIcon = (nodeType: NodeType, extension?: string): EfcIconProps => {
        if (nodeType === NodeType.Cabinet) {
            return cabinetIcon;
        } else if (nodeType === NodeType.Drawer) {
            return drawerIcon;
        } else if (nodeType === NodeType.Folder) {
            return newFolderIcon;
        } else if (nodeType === NodeType.File || nodeType === NodeType.TemplateFile) {
            switch (extension) {
                case '.pdf':
                    return pdfIcon;
                case '.doc':
                case '.docx':
                    return docIcon;
                case '.xls':
                case '.xlsx':
                    return excelIcon;
                case '.ppt':
                case '.pptx':
                    return powerpointIcon;
                case '.gddoc':
                case '.rtf':
                case '.txt':
                    return textFileIcon;
                case '.png':
                case '.jpg':
                case '.jpeg':
                case '.gif':
                case '.bmp':
                case '.tif':
                case '.tiff':
                case '.svg':
                    return imageIcon;
                case '.eml':
                    return emailIcon;
                case '.mov':
                case '.mpg':
                case '.mp4':
                    return movieIcon;
                case '.wav':
                case '.mp3':
                    return audioIcon;
                case '.zip':
                    return zipFileIcon;
                case '.csv':
                    return csvIcon;
                case '.gdspreadsheet':
                    return spreadsheetIcon;
                case '.gdpres':
                    return invoiceIcon;
                default:
                    return fileIcon;
            }
        }

        return fileIcon;
    };

    return {
        getItemIcon,

        loadIcons,
        newIcon,
        caretDown,
        caretUp,
        workIcon,
        circleUser,
        lightbulbIcon,
        wavepulseIcon,
        partyHornIcon,
        backArrowIcon,

        fileIcon,
        pdfIcon,
        docIcon,
        excelIcon,
        powerpointIcon,
        csvIcon,
        textFileIcon,
        codeFileIcon,
        fileLockIcon,
        zipFileIcon,

        movieIcon,
        imageIcon,
        audioIcon,
        emailIcon,
        spreadsheetIcon,
        invoiceIcon,

        homeIcon,
        documentsIcon,
        documentRequestsIcon,
        eSignatureIcon,
        formsIcon,
        workflowIcon,
        reportsIcon,
        allFeaturesIcon,
        favoritesIcon,
        portfolioIcon,
        lockIcon,
        unlockIcon,
        checkedOutIcon,
        checkmarkIcon,
        recycleBinIcon,
        deleteIcon,
        restoreRecycleBinIcon,
        templatesIcon,
        emailImportsIcon,
        profilesIcon,
        governanceIcon,
        wrenchIcon,
        usersIcon,
        groupsIcon,
        accessLinksIcon,
        auditLogsIcon,
        searchIcon,
        settingsIcon,
        securityPoliciesIcon,
        salesforceIcon,
        newFolderIcon,
        newFileIcon,
        newDocRequestIcon: newIcon,
        newESignatureIcon: newIcon,
        newFormIcon: newIcon,
        newTemplateLibraryIcon: newIcon,
        taskIcon,
        spinnerIcon,
    };
};
