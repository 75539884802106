import './App.css';
import { Login } from './auth/Login/Login';
import { useIcons } from './components/Icons';

function App() {
    const { loadIcons } = useIcons();

    loadIcons();

    return (
        <Login />
    );
}

export default App;
