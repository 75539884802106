import React from 'react';
import { Text } from '@efilecabinet/efc-atlantis-components';

interface LoginCardLayoutProps {
    children: React.ReactNode;
}

const LoginCardLayout = ({ children }: LoginCardLayoutProps) => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='page-wrapper d-flex flex-column justify-content-between vh-100'>
            <div className='content-wrapper d-flex flex-column justify-content-center align-items-center flex-grow-1'>
                <div className='back-and-content'>{children}</div>
            </div>
            <Text className='footer text-center p-3'>Copyright {currentYear} © Revver. All rights reserved.</Text>
        </div>
    );
};

export default LoginCardLayout;
