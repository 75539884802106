import { Button, Card, FormFeedback, FormGroup, Input, Label, Spinner, TooltipItem, Icon, Text, Form } from '@efilecabinet/efc-atlantis-components';
import React, { useEffect, useState } from 'react';
import { IUseFormObject } from '../../../utils/useForm';
import './PasswordAndSSO.css';
import { SSOAuth, SSOAuthObj } from './SSOAuth';
import { AuthProps, IdentityProvider, useAuthApi } from '../../../api/useAuthApi';
import { SamlResponseStatus } from '../../../api/useSamlApi';
import { useLoginHandler } from '../useLoginHandler';
import { OptionsTile } from '../../../components/OptionsTile';
import { RoutePath } from '../Login';
import { useLoginNavigation } from '../../../utils/useLoginNavigation';
import { ResetPasswordTypeEnum, useResetPasswordApi } from '../../../api/useResetPasswordApi';

export interface PasswordProps {
    loginForm: IUseFormObject<AuthProps>;
    show: boolean;
}

export const PasswordAndSSO = (props: PasswordProps) => {

    const navigate = useLoginNavigation();

    const emptySamlResponseStatus: SamlResponseStatus = {
        samlConfigurationId: undefined,
        id: undefined,
        hasData: false,
    };

    const { loginForm, show } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isSSOLoading, setIsSSOLoading] = useState(true);
    const [signInWithSSO, setSignInWithSSO] = useState(false);
    const [showPassword, setShowPasswordInput] = useState(false);
    const [autoLoginComplete, setAutoLoginComplete] = useState(false);
    const [attemptedAutoLogin, setAttemptedAutoLogin] = useState(false);
    const [ssoProviders, setSSOProviders] = useState<IdentityProvider[]>([]);
    const [activeSSOProvider, setActiveSSOProvider] = useState<IdentityProvider | undefined>(undefined);
    const [samlResponseStatus, setSamlResponseStatus] = useState<SamlResponseStatus>(emptySamlResponseStatus);
    const { login, loginWithLimitedAccess } = useLoginHandler(loginForm);
    const resetPasswordApi = useResetPasswordApi();

    const authApi = useAuthApi();

    const ssoAuth: SSOAuthObj = {
        signInWithSSO,
        setSignInWithSSO,
        activeSSOProvider,
        samlResponseStatus,
        setSamlResponseStatus,
    };

    const forgotPasswordClicked = () => {
        if (!!loginForm.model.username && !!loginForm.model.revverInstance) {
            resetPasswordApi.sendResetRequest(loginForm.model.username, loginForm.model.revverInstance.name, ResetPasswordTypeEnum.Email);
            navigate(RoutePath.ResetPassword);
        }
    };

    const loginWithPassword = async () => {
        if (!loginForm.model.password) {
            loginForm.addError('password', 'Required.');
            return;
        }

        if (!loginForm.model.revverInstance && !loginForm.model.regionIdentifier) {
            loginForm.addError('modelErrors', 'Invalid username or password. Try again.');
            return;
        }

        setIsLoading(true);

        await login();

        setIsLoading(false);
    };

    const loginWithSSO = (ssoProvider: IdentityProvider) => {
        if (ssoProvider.samlConfigurationId === activeSSOProvider?.samlConfigurationId) {
            setSignInWithSSO(true);
        } else {
            setActiveSSOProvider(ssoProvider);
        }
    };

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            loginWithPassword();
        }
    };

    const getSSOProviders = async () => {

        if (!loginForm.model.revverInstance) {
            setSSOProviders([]);
            setShowPasswordInput(true);
            setIsSSOLoading(false);
            return;
        }

        setIsSSOLoading(true);
        const providers = await authApi.getSSOProviders(loginForm.model);

        if (providers.length === 0) {
            setShowPasswordInput(true);
        }

        setSSOProviders(providers);
        setIsSSOLoading(false);
    };

    const autoLogin = async () => {
        setAutoLoginComplete(true);
        setActiveSSOProvider(ssoProviders[0]);
    };

    const handleSsoClick = () => {
        setShowPasswordInput(false);
        loginForm.clearErrors();
    }

    useEffect(() => {
        if (!!show) {
            getSSOProviders();
        } else {
            setShowPasswordInput(false);
            setIsSSOLoading(true);
            setSSOProviders([]);
        }
    }, [show]);

    useEffect(() => {
        if (ssoProviders.length === 1 && (!!loginForm.model.linked && loginForm.model.linked === 'true') && !autoLoginComplete && !attemptedAutoLogin) {
            setAttemptedAutoLogin(true);
            autoLogin();
        }
    }, [ssoProviders]);

    useEffect(() => {
        if (!!activeSSOProvider) {
            setSignInWithSSO(true);
        }
    }, [activeSSOProvider]);

    return (
        <>
            {show &&
                <>
                    <Card.Body className="d-flex flex-column justify-content-between">
                        <Form>
                            <FormGroup>
                                <Label className='m-0' for='username'>Email Address</Label>
                                <Input id='username' name='username' type='email' className='username-input-field-disabled' value={loginForm.model.username} disabled plaintext />
                            </FormGroup>
                            {!isSSOLoading && <>
                                {ssoProviders.length > 0 && !showPassword &&
                                    <>
                                        <Card.Subtitle className='my-3'>
                                            Select a sign in method
                                        </Card.Subtitle>
                                        {ssoProviders.map((ssoProvider, index) => (
                                            <OptionsTile key={index} onClick={() => loginWithSSO(ssoProvider)}>
                                                {ssoProvider.name}
                                            </OptionsTile>
                                        ))}
                                    </>
                                }
                                {(ssoProviders.length > 0 && !showPassword) &&
                                    <Button emphasis='low' color='primary' className='w-100 my-2' dataId='btnSignInWithPassword' onClick={() => setShowPasswordInput(true)}>
                                        Sign in with password
                                    </Button>
                                }

                                {(ssoProviders.length === 0 || showPassword) &&
                                    <>
                                        <FormGroup className='position-relative'>
                                            <Button emphasis='low' color='primary' className='button-no-hover-color input-context-button' dataId='btnForgotPassword' onClick={forgotPasswordClicked}>
                                                Forgot password?
                                            </Button>
                                            <Label for='password'>Password</Label>
                                            <Input id='password' name='password' type='password' dataId='txtPassword' value={loginForm.model.password} invalid={!!loginForm.errors?.password} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} autoFocus />
                                            <FormFeedback>{loginForm.errors?.password}</FormFeedback>
                                        </FormGroup>
                                        <Button className='w-100 my-3' dataId='btnLoginWithPassword' onClick={loginWithPassword}>
                                            {isLoading &&
                                                <Spinner size='sm' className='mt-1' />
                                            }
                                            {!isLoading &&
                                                <>
                                                    Sign in
                                                </>
                                            }
                                        </Button>
                                        {ssoProviders.length > 0 &&
                                            <Button emphasis='low' color='primary' className='w-100' dataId='btnSigninWithPassword' onClick={handleSsoClick}>
                                                Sign in with SSO
                                            </Button>
                                        }
                                        {loginForm.model.limitedAccess !== undefined &&
                                            <Button emphasis='low' color='primary' className='w-100' dataId='btnSigninWithPassword' onClick={loginWithLimitedAccess}>
                                                Proceed anyways with limited access
                                                <TooltipItem id='security-code-tooltip' position='right' message='After you sign in, you will have limited access to any account that has additional sign in requirements that you haven’t met yet. To get full access, you can switch accounts after sign in and complete all sign in requirements.'>
                                                    <Icon className='my-auto mx-2' color='primary' iconName='circle-question' light />
                                                </TooltipItem>
                                            </Button>
                                        }
                                    </>
                                }
                            </>}
                            {isSSOLoading &&
                                <div className='text-center mt-5'>
                                    <Spinner className='region-spinner mt-5' color='primary'>
                                        Loading...
                                    </Spinner>
                                </div>
                            }
                        </Form>
                        {ssoProviders.length > 0 && !showPassword &&
                            <Text className='mx-auto mt-5'>
                                Your sign in method may impact your account access
                                <TooltipItem id='security-code-tooltip' position='right' message='After you sign in, you will have limited access to any account that has additional sign in requirements that you haven’t met yet. To get full access, you can switch accounts after sign in and complete all sign in requirements.'>
                                    <Icon className='my-auto mx-2' color='primary' iconName='circle-question' light />
                                </TooltipItem>
                            </Text>
                        }
                        {showPassword &&
                            <Button className='w-100' emphasis='low' color='primary' dataId='btnNewUser' onClick={() => navigate(RoutePath.NewUser)}>
                                New user?
                            </Button>
                        }
                    </Card.Body>

                    <SSOAuth loginForm={loginForm} ssoAuthObj={ssoAuth} />
                </>
            }
        </>
    );
};
