import Api from '../api';
import { AxiosResponse } from 'axios';
export interface CustomBranding {
    accountIdentifier: string;
    accountLogoImgString: string;
    accountMessage: string;
    accountName: string;
    cname: string;
    logoOnSignInPage: boolean;
    logoWithMessage: boolean;
    isUtopiaBranding: boolean;
}
export const useBrandingApi = () => {
    const getBrandingByCname = async (cname: string, region: string | undefined) => {
        const regionParam = !!region ? `?region=${region}` : '';
        const resp: AxiosResponse = await Api.get(`api/Branding/cname/${cname}${regionParam}`);
        return resp.data as CustomBranding;
    };
    return {
        getBrandingByCname
    };
};

