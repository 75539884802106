import { MfaTypeEnum, Role } from '../auth/AuthenticationTypes';

// Enums

export enum DaysOfWeekEnum {
    Sunday = 1,
    Monday = 2,
    Tuesday = 4,
    Wednesday = 8,
    Thursday = 16,
    Friday = 32,
    Saturday = 64,
}

export enum NodeType {
    Account = 1,
    Shared = 2,
    Cabinet = 4,
    Drawer = 5,
    Folder = 6,
    File = 7,
    TemplateLibrary = 11,
    Template = 12,
    TemplateFolder = 13,
    TemplateFile = 14,
    PredefinedDocument = 15,
    GDWorkspace = 16,
    ODWorkspace = 17,
    Record = 18,
}

export enum PermissionInheritanceEnum {
    Default = 0,
    AppliesToSelfOnly = 20,
}

export enum ProfileItemTypeEnum {
    Text = 0,
    Email = 1,
    Number = 2,
    Date = 3,
    PhoneNumber = 4,
    CheckBox = 5,
    Currency = 6,
    Memo = 7,
    PresetValues = 8,
    Percentage = 9,
    HiddenText = 10,
}

export enum RecentNodeTypeEnum {
    Uploads = 0,
    Downloads = 1,
    All = 2,
}

// Interfaces
export interface IActiveSession {
    id: number;
    roleID: number;
    roleName: string;
    clientApplication: IClientApplication;
    ipAddress: string;
}

export interface IClientApplication {
    id: string;
    name: string;
    website: string;
    description: string;
    organization: string;
}

export interface ICheckedOutFile {
    node: INode;
    checkedOutBy: string;
    checkedOutOn: Date;
    path: string;
    o365ClientLock: string;
    o365ClientLockedOn: Date | null;
}

export interface ICheckOutInfo {
    nodeId: number;
    checkedOutByRoleID: number;
    checkedOutOn: Date;
    o365ClientLock: string;
    o365ClientLockedOn: Date;
}

export interface IFileExtension {
    fileExtension: string;
}

export interface INode {
    id: string;
    accountID: number;
    parentID: string;
    name: string;
    size: number;
    systemType: NodeType;
    fileInfo: IFileExtension;
    createdOn: string;
    createdBy: string;
    createdByUserId: number;
    modifiedOn: string;
    hasChildren: boolean;
    checkoutInfo: ICheckOutInfo;
}

export interface INodeRecent extends INode {
    auditLogDate: string;
}

export interface IPermission {
    id: number;
    nodeID?: number;
    roleID?: number;
    createdByUserID?: number;
    createdByUsername: string;
    name: string;
    systemType: number;
    createdBy: string;
    role: string;
    roleData: Role;
    preview: boolean;
    view: boolean;
    read: boolean;
    download: boolean;
    uploadFiles: boolean;
    createDirectories: boolean;
    write: boolean;
    delete: boolean;
    enforce: boolean;
    admin: boolean;
    restrict: boolean;
    remove: boolean;
    inheritanceBehavior: PermissionInheritanceEnum;
    createdOn: Date;
    childPermission: IPermission;
}

export interface IPortfolio {
    id: number;
    name: string;
    portfolioNodes: IPortfolioNode[];
}

export interface IPortfolioNode {
    nodeID: string;
}

export interface IProfile {
    id: number;
    accountID: number;
    name: string;
    isDeleted: boolean;
    profileItems: IProfileItem[];
}

export interface IProfileItemPresetValue {
    id: number;
    value: string;
    order: number;
}

export interface IProfileItemHiddenCharacterRange {
    id: number;
    startingPosition: number;
    endingPosition: number;
}

export interface IProfileItemHiddenCharacterConfiguration {
    id: number;
    hideSpecifiedCharacters: boolean;
    fromBeginning?: number;
    fromEnd?: number;
    characterRanges: IProfileItemHiddenCharacterRange[];
}

export interface IProfileItem {
    id: number;
    accountID: number;
    name: string;
    type: ProfileItemTypeEnum;
    value: any;
    required: boolean;
    joinID: number;
    valueID: number;
    include: boolean;
    position?: number;
    presetValueID?: number;
    presetValues: IProfileItemPresetValue[];
    hiddenCharacterID?: number;
    hiddenCharacters: IProfileItemHiddenCharacterConfiguration;
    isVisible: boolean;
    isDeleted: boolean;
    profileID?: number;
    visibleDecimalPlaces?: number;
}

export interface IRecentNodeQueryParams {
    recentType: RecentNodeTypeEnum;
    start: number;
    count: number;
}

export interface ISalesforceMapping {
    id: number;
    accountID: number;
    sFObjectType: string;
    creationFieldName: string;
    userPermission: IPermission;
    groupPermission: IPermission;
    mappingDestinationCount: number;
}

export interface ISalesforceMappingDestination {
    id: number;
    salesforceMappingID: number;
    accountID: number;
    parentNode: INode;
    role: Role;
}

export interface ISecurityPolicyLoginTime {
    id: number;
    securityPolicyID: number;
    daysOfWeekAllowed: DaysOfWeekEnum[];
    startTimeMinutes: number;
    endTimeMinutes: number;
    accountID: number;
}

export interface ISecurityPolicyIpAddress {
    id: number;
    securityPolicyID: number;
    ipAddress: string;
    accountID: number;
}

export interface ISecurityPolicyDomain {
    id: number;
    securityPolicyID: number;
    domain: string;
    accountID: number;
}

export interface ISecurityPolicy {
    id: number;
    accountID: number;
    name: string;
    passwordLowerCase: boolean;
    passwordUpperCase: boolean;
    passwordNumbers: boolean;
    passwordSpecialCharacters: boolean;
    minimumPasswordLength: number;
    passwordChangeFrequency?: number;
    mfaIsRequired: boolean;
    mfaType?: MfaTypeEnum;
    mfaRequiredDays?: number;
    specificLoginTimes: boolean;
    specificIpAddresses: boolean;
    loginTimes: ISecurityPolicyLoginTime[];
    ipAddresses: ISecurityPolicyIpAddress[];
    domains: ISecurityPolicyDomain[];
    ssoIsRequired: boolean;
    outgoingEmailSettingIsRequired: boolean;
    maxSessionTimeout?: number;
}

export interface ITask {
    taskId: string;
    text: string;
    status?: TaskStatus;
}

export enum TaskStatus {
    Assigned = 0,
    Completed = 1,
    Deleted = 2,
}
