import { useEffect, useState } from "react";
import { ISecurityPolicy } from "../../../../utils/CommonTypes";

export interface PasswordValidationProps {
    passwordMatch: boolean;
    passwordLength: boolean;
    validateLength: number;
    passwordLowerCase?: boolean;
    passwordUpperCase?: boolean;
    passwordNumber?: boolean;
    passwordSymbol?: boolean;
}

export const useNewPasswordValidator = (securityPolicies?: ISecurityPolicy[]) => {

    const [validateLowerCase, setValidateLowerCase] = useState(false);
    const [validateUpperCase, setValidateUpperCase] = useState(false);
    const [validateNumber, setValidateNumber] = useState(false);
    const [validateSymbol, setValidateSymbol] = useState(false);
    const [validateLength, setValidateLength] = useState(4);

    const setPasswordValidation = (securityPolicies: ISecurityPolicy[]) => {
        for (const policy of securityPolicies) {
            setValidateLowerCase(policy.passwordLowerCase ? policy.passwordLowerCase : validateLowerCase);
            setValidateUpperCase(policy.passwordUpperCase ? policy.passwordUpperCase : validateUpperCase);
            setValidateNumber(policy.passwordNumbers ? policy.passwordNumbers : validateNumber);
            setValidateSymbol(policy.passwordSpecialCharacters ? policy.passwordSpecialCharacters : validateSymbol);
            setValidateLength(policy.minimumPasswordLength > validateLength ? policy.minimumPasswordLength : validateLength);
        }
    }

    const passwordMatches = (newPassword?: string, matchPassword?: string) => {
        return !!newPassword && matchPassword == newPassword;
    }

    const passwordHasLowerCase = (newPassword?: string) => {
        const lowerCaseLetters = /[a-z]/g;
        return newPassword ? !!newPassword.match(lowerCaseLetters) : false;
    }

    const passwordHasUpperCase = (newPassword?: string) => {
        const upperCaseLetters = /[A-Z]/g;
        return newPassword ? !!newPassword.match(upperCaseLetters) : false;
    }

    const passwordHasNumbers = (newPassword?: string) => {
        const numbers = /[0-9]/g;
        return newPassword ? !!newPassword.match(numbers) : false;
    }

    const passwordHasSymbols = (newPassword?: string) => {
        const symbols = /[^0-9a-zA-Z]/g;
        return newPassword ? !!newPassword.match(symbols) : false;
    }

    const passwordHasLength = (newPassword?: string) => {
        return newPassword ? newPassword.length >= validateLength : false;
    }

    const getPasswordValidationProps = (newPassword?: string, matchPassword?: string): PasswordValidationProps => {

        const validatorProps: PasswordValidationProps = {
            passwordMatch: passwordMatches(newPassword, matchPassword),
            passwordLength: passwordHasLength(newPassword),
            validateLength: validateLength,
        };

        if (validateLowerCase) {
            validatorProps.passwordLowerCase = passwordHasLowerCase(newPassword);
        }

        if (validateUpperCase) {
            validatorProps.passwordUpperCase = passwordHasUpperCase(newPassword);
        }

        if (validateNumber) {
            validatorProps.passwordNumber = passwordHasNumbers(newPassword);
        }

        if (validateSymbol) {
            validatorProps.passwordSymbol = passwordHasSymbols(newPassword);
        }

        return validatorProps;
    };

    useEffect(() => {
        if (!!securityPolicies) {
            setPasswordValidation(securityPolicies);
        }
    }, [securityPolicies]);

    return {
        getPasswordValidationProps,

        validateUpperCase,
        validateLowerCase,
        validateLength,
        validateNumber,
        validateSymbol
    };
};
