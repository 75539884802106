import { useEffect, useState } from 'react';
import { Button, Card, Nav, Text, RevverIcon, Spinner } from '@efilecabinet/efc-atlantis-components';
import { Route, Routes, useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AuthProps } from '../../api/useAuthApi';
import { CustomBranding, useBrandingApi } from '../../api/useBrandingApi';
import { ThemeEnum } from '../../components/Colors';
import { useIcons } from '../../components/Icons';
import { useForm } from '../../utils/useForm';
import { useLoginUtilities } from './useLoginUtilities';
import { useLoginNavigation } from '../../utils/useLoginNavigation';
import { useSSOAuth } from '../../utils/useSSOAuth';
import { Status, useStatusMessage } from '../../utils/useStatusMessage';
import { RevverInstance } from '../AuthenticationTypes';
import './Login.css';
import { MFA } from './MFA';
import { NewUser } from './NewUser/NewUser';
import { NewUserLink } from './NewUser/NewUserLink';
import { ManualEmailSetting } from './OutgoingEmail/ManualEmailSetting';
import { OutgoingEmailOptions } from './OutgoingEmail/OutgoingEmailOptions';
import { PasswordAndSSO } from './PasswordAndSSO';
import { SSOAllowFullAccess } from './PasswordAndSSO/SSOAllowFullAccess';
import { SSOGrantFullAccess } from './PasswordAndSSO/SSOAllowFullAccess/SSOGrantFullAccess';
import { Region } from './Region';
import { ResetPassword } from './ResetPassword';
import { NewPasswordInput } from './ResetPassword/NewPasswordInput';
import { SentEmail } from './ResetPassword/SentEmail';
import { SentText } from './ResetPassword/SentText';
import { Username } from './Username';
import { AccessLink } from './AccessLink';
import { EulaModal } from './EulaModal';
import { Exception } from './AccessLink/Exception/Exception';
import LoginCardLayout from '../../components/LoginCardLayout';
import secureLocalStorage from "react-secure-storage";

export enum LoginStateEnum {
    Username = 0,
    Region = 1,
    PasswordAndSSO = 2,
    ResetPassword = 3,
    MFA = 4,
    MFAAppSetup = 5,
    OutgoingEmail = 6,
}

export enum AuthFlowTypeEnum {
    Login,
    SsoRedirect,
    SkipIfAuthenticated,
}

export enum RoutePath {
    Username = '/',
    Region = '/region',
    PasswordAndSSO = '/password',

    SSOAllowFullAccess = '/sso/authorization',
    SSOGrantFullAccess = '/sso/grant',

    ResetPassword = '/resetPassword',
    ResetPasswordLink = '/resetPassword/:userId/:resetToken',
    SentEmail = '/resetPassword/email',
    SentText = '/resetPassword/text',
    NewPasswordInput = '/resetPassword/newPassword',

    MFA = '/mfa',

    OutgoingEmailOptions = '/outgoingEmail/options',
    OutgoingEmailManual = '/outgoingEmail/manual',

    NewUser = '/newUser',
    NewUserLink = '/newUser/:userId/:resetToken',
    NewUserInput = '/newUser/newPassword',
    NewUserEmail = '/newUser/email',

    AccessLink = '/accessLink',
    AccessLinkException = '/accessLinkException',
}

export const Login = () => {

    const [queryParameters] = useSearchParams();
    const navigate = useLoginNavigation();
    const location = useLocation();
    const { getBrandingByCname } = useBrandingApi();

    const initLoginObj: AuthProps = {
        password: '',
        revverInstance: undefined,
        authenticationRequestType: undefined,
        samlToken: undefined,
        limitedAccess: undefined,
        requiresMFA: false,
        requiresEmailSetting: false,
        appMFA: false,
        emailMFA: false,
        hasMultipleRegions: false,
        securityCode: (secureLocalStorage.getItem("mfaToken") ?? '') as string,
        passwordExpired: false,
        newUserExpired: false,
        isSalesforce: false,

        username: queryParameters.get('username') ?? '',
        newExperience: queryParameters.get('newExperience'),
        linked: queryParameters.get('linked'),
        inactivityTimeout: queryParameters.get('inactivityTimeout'),
        regionIdentifier: queryParameters.get('region'),

        accessLinkToken: queryParameters.get('token'),
        accessLinkNeedsInfo: false,
        accessLinkExpired: false,
        accessLinkNoUsesRemaining: false,
        uploadLinkMissing: false,
        anonymousUserFirstName: undefined,
        anonymousUserLastName: undefined,
        anonymousUserEmail: undefined,

        cname: undefined,

        agreeToEula: false,
        requiresEulaAgreement: false,
        skipEulaCheck: false,
    };

    const { statusState, setStatus, clearStatus } = useStatusMessage();

    const { backArrowIcon } = useIcons();

    const loginForm = useForm<AuthProps>(initLoginObj);
    const { continueToRevverIfAuthenticated } = useLoginUtilities();
    const { redirectIfNeeded } = useSSOAuth(loginForm);

    const [authFlowType, setAuthFlowType] = useState(AuthFlowTypeEnum.Login);
    const [customBranding, setCustomBranding] = useState<CustomBranding>();
    const [showEulaModal, setShowEulaModal] = useState(true);

    const getFromLocalStorage = (property: string) => {
        const item = localStorage.getItem(property);
        if (!!item && item !== 'undefined') {
            return item;
        }
    };

    window.onload = () => {
        redirectIfNeeded(setAuthFlowType);
    };

    const clearLoginForm = () => {
        // loginForm.setModelProperty('username', ''); //we may or may not want to clear the username?
        loginForm.setModelProperty('password', '');
        loginForm.setModelProperty('revverInstance', undefined);
        loginForm.setModelProperty('authenticationRequestType', undefined);
        loginForm.setModelProperty('limitedAccess', undefined);
        loginForm.setModelProperty('userId', undefined);
        loginForm.setModelProperty('requiresMFA', false);
        loginForm.setModelProperty('requiresEmailSetting', false);
        loginForm.setModelProperty('resetToken', undefined);
        loginForm.setModelProperty('newPassword', undefined);
        loginForm.setModelProperty('matchPassword', undefined);
        loginForm.setModelProperty('securityCode', undefined);
        loginForm.setModelProperty('appMFA', false);
        loginForm.setModelProperty('emailMFA', false);
        loginForm.setModelProperty('hasMultipleRegions', false);
        loginForm.setModelProperty('securityCode', '');
        loginForm.setModelProperty('passwordExpired', false);
        loginForm.setModelProperty('newUserExpired', false);
        loginForm.setModelProperty('agreeToEula', false);
        loginForm.setModelProperty('requiresEulaAgreement', false);
        loginForm.setModelProperty('skipEulaCheck', false);
    };

    const performAutoLogin = () => {
        let rememberMe: boolean = !!getFromLocalStorage('rememberMe') && getFromLocalStorage('rememberMe') === 'true';
        let revverInstance: RevverInstance | undefined = !!getFromLocalStorage('revverInstance') ? JSON.parse(getFromLocalStorage('revverInstance') as string) as RevverInstance : undefined;
        let userName: string = getFromLocalStorage('username') as string;
        let userId: number | undefined = !!getFromLocalStorage('loginUserId') ? JSON.parse(getFromLocalStorage('loginUserId') as string) as number : undefined;

        if (rememberMe && !!userName) {
            loginForm.setModelProperty('username', userName);

            if (!!revverInstance && !!userId) {
                loginForm.setModelProperty('revverInstance', revverInstance);
                loginForm.setModelProperty('userId', userId);
                navigate(RoutePath.PasswordAndSSO);
            }
            else {
                navigate(RoutePath.Region);
            }
        }
    };

    const next = () => {
        loginForm.clearErrors();

        let rememberMe: boolean = !!getFromLocalStorage('rememberMe') && getFromLocalStorage('rememberMe') === 'true';

        switch (location.pathname) {
            case RoutePath.Username:
                if (rememberMe) {
                    localStorage.setItem('username', loginForm.model.username);
                }
                navigate(RoutePath.Region);
                break;
            case RoutePath.Region:
                navigate(RoutePath.PasswordAndSSO);
                break;
        }
    };

    const prev = () => {
        loginForm.clearErrors();

        switch (location.pathname) {
            case RoutePath.PasswordAndSSO:
                if (!loginForm.model.revverInstance || !loginForm.model.hasMultipleRegions) {
                    clearLoginForm();
                    navigate(RoutePath.Username);
                } else {
                    loginForm.setModelProperty('revverInstance', undefined);
                    navigate(RoutePath.Region);
                }
                break;
            case RoutePath.Region:
            case RoutePath.NewUser:
            case RoutePath.NewPasswordInput:
                clearLoginForm();
                navigate(RoutePath.Username);
                break;
            case RoutePath.MFA:
                loginForm.setModelProperty('requiresMFA', false);
                loginForm.setModelProperty('securityCode', undefined);
                navigate(RoutePath.PasswordAndSSO);
                break;
            case RoutePath.SentEmail:
                navigate(RoutePath.PasswordAndSSO);
                break;
            case RoutePath.SentText:
                navigate(RoutePath.SentEmail);
                break;
            case RoutePath.NewUserEmail:
                navigate(RoutePath.NewUser);
                break;
            default:
                window.history.back();
                break;
        }
    };

    const getBrandingDomain = async (regionIdentifier: string | undefined) => {
        const hostParts = window.location.host.split('.');

        if (hostParts.length > 3) {
            const cname = hostParts[0];
            loginForm.setModelProperty('cname', cname);
            const branding = await getBrandingByCname(cname, regionIdentifier);

            if (!!branding) {
                setCustomBranding(branding);
            }
            else {
                let removedCnameUrlRedirect = '';
                
                for (let i = 1; i < hostParts.length; i++) {
                    removedCnameUrlRedirect += hostParts[i];
                    if (i < hostParts.length - 1) {
                        removedCnameUrlRedirect += ".";
                    }
                }

                window.location.host = removedCnameUrlRedirect;
            }
        }
    };

    const showRegionLabel = (): boolean => {
        return !!loginForm.model.hasMultipleRegions &&
            !!loginForm.model.revverInstance?.name &&
            location.pathname !== RoutePath.Region;
    };

    const formatRegionName = (name?: string) => {
        if (!!name) {
            if (name.includes('Rubex')) {
                return name.split('Rubex')[1];
            } else {
                return name;
            }
        }
    };

    const toggleEulaModal = () => {
        loginForm.setModelProperty('requiresEulaAgreement', false);
        setShowEulaModal((prevState) => !prevState);
    };

    const showInactivityTimeOut = () => {
        if (loginForm.model.inactivityTimeout) {
            loginForm.addError('modelWarnings', 'You were logged out due to inactivity. Sign in again.');
        }
    };

    useEffect(() => {
        if (!!loginForm.model.inactivityTimeout) {
            showInactivityTimeOut();
        }

    }, [loginForm.model.inactivityTimeout]);

    useEffect(() => {
        if (!!loginForm.model.regionIdentifier) {
            getBrandingDomain(loginForm.model.regionIdentifier);
        }

    }, [window.location.host]);

    useEffect(() => {
        if (!!loginForm.model.revverInstance?.name && !customBranding) {
            getBrandingDomain(loginForm.model.revverInstance.name);
        }

    }, [loginForm.model.revverInstance]);

    useEffect(() => {
        if (!!loginForm.errors?.modelWarnings) {
            setStatus(loginForm.errors?.modelWarnings, ThemeEnum.Warning);
        } else {
            clearStatus();
        }
    }, [loginForm.errors?.modelWarnings]);

    useEffect(() => {
        if (!!loginForm.errors?.modelErrors) {
            setStatus(loginForm.errors?.modelErrors, ThemeEnum.Danger);
        } else {
            clearStatus();
        }
    }, [loginForm.errors?.modelErrors]);

    useEffect(() => {
        if (!!loginForm.model.requiresMFA) {
            navigate(RoutePath.MFA);
        }
    }, [loginForm.model.requiresMFA]);

    useEffect(() => {
        if (!!loginForm.model.requiresEmailSetting) {
            navigate(RoutePath.OutgoingEmailOptions);
        }
    }, [loginForm.model.requiresEmailSetting]);

    useEffect(() => {
        if (!!loginForm.model.passwordExpired) {
            navigate(RoutePath.ResetPassword);
        }
    }, [loginForm.model.passwordExpired]);

    useEffect(() => {
        if (!!loginForm.model.samlRoleAuthentication) {
            navigate(RoutePath.SSOAllowFullAccess);
        }
    }, [loginForm.model.samlRoleAuthentication]);

    useEffect(() => {
        if (!!loginForm.model.requiresEulaAgreement) {
            setShowEulaModal(true);
        } else {
            setShowEulaModal(false);
        }
    }, [loginForm.model.requiresEulaAgreement]);

    useEffect(() => {
        if (!!loginForm.model.accessLinkNoUsesRemaining || !!loginForm.model.accessLinkExpired || !!loginForm.model.uploadLinkMissing) {
            navigate(RoutePath.AccessLinkException);
        }
    }, [loginForm.model.accessLinkNoUsesRemaining, loginForm.model.accessLinkExpired, loginForm.model.uploadLinkMissing]);

    useEffect(() => {

        if (location.pathname.toLowerCase() !== RoutePath.AccessLink.toLowerCase()) {
            continueToRevverIfAuthenticated(setAuthFlowType);
        }

        if (location.pathname === RoutePath.Username) {
            performAutoLogin();
        }

        if (location.pathname !== RoutePath.Username && location.pathname.toLowerCase() !== RoutePath.AccessLink.toLowerCase() && !loginForm.model.username && !location.pathname.includes(RoutePath.ResetPassword)) {
            navigate(RoutePath.Username);
        }

        if (!customBranding) {
            getBrandingDomain(undefined);
        }
    }, []);

    return (
        <>
            {location.pathname === RoutePath.AccessLinkException ? (
                <Exception loginForm={loginForm} />
            ) : (
                <LoginCardLayout>
                    {location.pathname.toLowerCase() !== RoutePath.AccessLink.toLowerCase() && (
                        <Button icon={backArrowIcon} emphasis='low' onClick={prev} className={`mb-2 button-no-hover-color ${location.pathname !== RoutePath.Username ? 'visible' : 'invisible'}`}>
                            Back
                        </Button>
                    )}

                    <Card body fluid className='login-card mx-4'>
                        {showRegionLabel() &&
                            <div className='region-label position-absolute text-center'>
                                <Text size='sm' className='px-1'>
                                    {formatRegionName(loginForm.model.revverInstance?.name)}
                                </Text>
                            </div>
                        }
                        
                        <div className='d-flex justify-content-center'>
                            <RevverIcon color='#7514BD' className='revver-icon' />
                        </div>

                        <div className='divider mx-auto'></div>

                        <Status className='mx-3 position-relative' statusState={statusState} />

                        {!!customBranding && !!customBranding.accountName && (
                            <Text className='text-center mt-4' semibold>
                                Signing in to the account, {customBranding.accountName}
                            </Text>
                        )}

                        {!!customBranding && customBranding.logoOnSignInPage &&
                            <div className='branding-img-container'>
                                <img src={customBranding.accountLogoImgString} className='branding-img' />
                            </div>
                        }

                        {(() => {
                            switch (authFlowType) {
                                case AuthFlowTypeEnum.SsoRedirect:
                                    return (
                                        <Card.Body className="d-flex flex-column">
                                            <Card.Subtitle className='mx-auto'>
                                                Signing in with your SSO Provider...
                                            </Card.Subtitle>
                                            <div className='text-center mt-5'>
                                                <Spinner className='region-spinner mt-5' color='primary'>
                                                    Loading...
                                                </Spinner>
                                            </div>
                                        </Card.Body>
                                    );
                                case AuthFlowTypeEnum.SkipIfAuthenticated:
                                    return (
                                        <Card.Body className="d-flex flex-column">   
                                            <Card.Subtitle className='mx-auto'>
                                                Loading...
                                            </Card.Subtitle>
                                            <div className='text-center mt-5'>
                                                <Spinner className='region-spinner mt-5' color='primary'>
                                                    Loading...
                                                </Spinner>
                                            </div>
                                        </Card.Body>
                                    );
                                case AuthFlowTypeEnum.Login:
                                default:
                                    return (
                                        <Routes>
                                            <Route path={RoutePath.AccessLink} element={<AccessLink loginForm={loginForm} />} />

                                            <Route path={RoutePath.Username} element={<Username loginForm={loginForm} show={location.pathname === RoutePath.Username} next={next} />} />

                                            <Route path={RoutePath.Region} element={<Region loginForm={loginForm} show={location.pathname === RoutePath.Region} next={next} />} />
                                            <Route path={RoutePath.PasswordAndSSO} element={<PasswordAndSSO loginForm={loginForm} show={location.pathname === RoutePath.PasswordAndSSO} />} />

                                            <Route path={RoutePath.SSOAllowFullAccess} element={<SSOAllowFullAccess loginForm={loginForm} show={location.pathname === RoutePath.SSOAllowFullAccess} />} />
                                            <Route path={RoutePath.SSOGrantFullAccess} element={<SSOGrantFullAccess loginForm={loginForm} show={location.pathname === RoutePath.SSOGrantFullAccess} />} />

                                            <Route path={RoutePath.ResetPassword} element={<ResetPassword loginForm={loginForm} />} />
                                            <Route path={RoutePath.ResetPasswordLink} element={<ResetPassword loginForm={loginForm} />} />
                                            <Route path={RoutePath.SentText} element={<SentText show={location.pathname === RoutePath.SentText} loginForm={loginForm} />} />
                                            <Route path={RoutePath.SentEmail} element={<SentEmail show={location.pathname === RoutePath.SentEmail} loginForm={loginForm} />} />
                                            <Route path={RoutePath.NewPasswordInput} element={<NewPasswordInput show={location.pathname === RoutePath.NewPasswordInput} loginForm={loginForm} />} />

                                            <Route path={RoutePath.MFA} element={<MFA loginForm={loginForm} show={location.pathname === RoutePath.MFA} />} />

                                            <Route path={RoutePath.OutgoingEmailOptions} element={<OutgoingEmailOptions loginForm={loginForm} show={location.pathname === RoutePath.OutgoingEmailOptions} />} />
                                            <Route path={RoutePath.OutgoingEmailManual} element={<ManualEmailSetting loginForm={loginForm} show={location.pathname === RoutePath.OutgoingEmailManual} />} />

                                            <Route path={RoutePath.NewUserLink} element={<NewUserLink loginForm={loginForm} />} />
                                            <Route path={RoutePath.NewUser} element={<NewUser loginForm={loginForm} show={location.pathname === RoutePath.NewUser} />} />
                                            <Route path={RoutePath.NewUserInput} element={<NewPasswordInput loginForm={loginForm} isNewUser show={location.pathname === RoutePath.NewUserInput} />} />
                                            <Route path={RoutePath.NewUserEmail} element={<SentEmail loginForm={loginForm} isNewUser show={location.pathname === RoutePath.NewUserEmail} />} />
                                        </Routes>
                                    );
                            }
                        })()}
                    </Card>

                    <Nav className='mx-4 mt-4 pe-1 legacy-links'>
                        <Nav.Link href='https://www.revverdocs.com/terms-of-use/' color='primary'>
                            Terms of Use
                        </Nav.Link>
                        <Nav.Link href='https://www.revverdocs.com/privacy-policy/' color='primary'>
                            Privacy Policy
                        </Nav.Link>
                    </Nav>
                </LoginCardLayout>
            )}
            {!!showEulaModal && <EulaModal isOpen={showEulaModal} toggle={toggleEulaModal} loginForm={loginForm} />}
        </>
    );
};
