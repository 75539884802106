import { AuthProps } from "../api/useAuthApi";
import { SamlRoleAuthentication, useSamlApi } from "../api/useSamlApi";
import { AuthenticationTypeEnum } from '../auth/AuthenticationTypes';
import { AuthFlowTypeEnum } from "../auth/Login/Login";
import { useLoginHandler } from '../auth/Login/useLoginHandler';
import { IUseFormObject } from "./useForm";

export const useSSOAuth = (loginForm: IUseFormObject<AuthProps>) => {

    const { login } = useLoginHandler(loginForm);
    const { getSamlRoleAuth } = useSamlApi();
    const redirectToken = 'redirectToken';
    const regionUrl = 'regionUrl';

    const samlLogin = async (roleAuth?: SamlRoleAuthentication) => {
        if (!!roleAuth) {
            await doLogin(roleAuth);
            return;
        }

        const samlRoleAuth = await getSamlRoleAuth(loginForm.model.samlToken as string, loginForm.model.revverInstance?.utopiaUrl as string);

        if (samlRoleAuth?.allowFullAuthentication == undefined) {
            loginForm.setModelProperty('samlRoleAuthentication', samlRoleAuth);
        }
        else {
            await doLogin(samlRoleAuth);
        }
    };

    const doLogin = async (samlRoleAuth: SamlRoleAuthentication) => {
        loginForm.model.authenticationRequestType = samlRoleAuth.allowFullAuthentication ? AuthenticationTypeEnum.SSO_Saml_Full_Access : AuthenticationTypeEnum.SSO_Saml;
        await login();
    };

    const redirectIfNeeded = async (setAuthFlowType: Function) => {
        const token = new URLSearchParams(window.location.search).get(redirectToken);
        const url = new URLSearchParams(window.location.search).get(regionUrl);
        if (!!token && !!url) {
            setAuthFlowType(AuthFlowTypeEnum.SsoRedirect);

            loginForm.model.revverInstance = {
                utopiaInstanceId: '00000000-0000-0000-0000-000000000000',
                utopiaUrl: `${url}/`,
                name: '',
            };
            loginForm.model.samlToken = token;

            samlLogin();
        }
        else if (!token && !!url) {
            window.alert = function () { }; // Can't perform window.close() if an alert is showing, this disables it
            window.close();
        }
    };

    return {
        samlLogin,
        redirectIfNeeded,
    };
};