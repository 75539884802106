import { Button, Card, FormFeedback, FormGroup, Icon, Input, Label, Spinner, Text } from '@efilecabinet/efc-atlantis-components';
import { useState, useEffect } from 'react';
import { AuthProps } from '../../../../api/useAuthApi';
import { ResetPasswordTypeEnum, useResetPasswordApi } from '../../../../api/useResetPasswordApi';
import { IUseFormObject } from '../../../../utils/useForm';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';
import { RoutePath } from '../../Login';
import './SentText.css';

export interface SentTextProps {
    loginForm: IUseFormObject<AuthProps>,
    show: boolean,
}

export const SentText = (props: SentTextProps) => {

    const { loginForm, show } = props;

    const navigate = useLoginNavigation();

    const [isLoading, setIsLoading] = useState(false);
    const [resendSuccessful, setResendSuccessful] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);

    const resetPasswordApi = useResetPasswordApi();

    const validateResetToken = async (username: string, instanceName: string, userId: number, resetToken: string) => {
        setIsLoading(true);
        const isValidToken = await resetPasswordApi.validateResetToken(username, instanceName, userId, resetToken);
        setIsLoading(false);
        return !!isValidToken;
    };

    const resendResetCode = async () => {
        if (!!loginForm.model.username && !!loginForm.model.revverInstance) {
            setResendLoading(true);
            try {
                await resetPasswordApi.sendResetRequest(loginForm.model.username, loginForm.model.revverInstance.name, ResetPasswordTypeEnum.TextMessage);
                setResendSuccessful(true);
                setTimeout(() => {
                    setResendSuccessful(false);
                }, 2500)
            }
            catch (error: any) {
                setResendSuccessful(false);
                let errorData = error?.response?.data;
                if (!!errorData) {
                    loginForm.addError('modelErrors', errorData);
                }
                else {
                    loginForm.addError('modelErrors', error);
                }
            }
            setResendLoading(false);
        }
    };

    const onSubmit = async () => {
        if (!!loginForm.model.resetToken && !!loginForm.model.userId && !!loginForm.model.username && !!loginForm.model.revverInstance) {
            let tokenIsValid = await validateResetToken(loginForm.model.username, loginForm.model.revverInstance.name, loginForm.model.userId, loginForm.model.resetToken);

            if (tokenIsValid) {
                navigate(RoutePath.NewPasswordInput);
            }
            else {
                loginForm.addError('resetToken', 'Invalid code.');
            }
        }
    };

    const canSubmit = () => {
        return !!loginForm.model.resetToken &&
            !!loginForm.model.userId &&
            !!loginForm.model.username &&
            !!loginForm.model.revverInstance &&
            loginForm.model.resetToken.length === 6;
    };

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            await onSubmit();
        }
    };

    return (
        <>
            {show &&
                <>
                    <Card.Title size='xl' bold className='mt-4 mx-auto'>
                        <Icon className='sent-confirm-icon' color='primary' iconName='circle-check' />
                        Text sent
                    </Card.Title>
                    <Card.Body>
                        <FormGroup className='position-relative'>
                            <Button emphasis='low' color='primary' className='button-no-hover-color input-context-button' onClick={resendResetCode}>
                                {resendSuccessful &&
                                    <Icon className='my-auto' color='primary' iconName='circle-check' />
                                }
                                {!resendSuccessful && resendLoading &&
                                    <Spinner size='sm' className='me-2' />
                                }
                                Resend
                            </Button>
                            <Label for='resetToken'>
                                Code
                            </Label>
                            <Input id='resetToken' name='resetToken' type='text' value={loginForm.model.resetToken} invalid={!!loginForm.errors?.resetToken} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} />
                            <FormFeedback>{loginForm.errors?.resetToken}</FormFeedback>
                        </FormGroup>
                        <Button className='mx-auto w-100 my-5' onClick={onSubmit} disabled={!canSubmit()}>
                            {isLoading &&
                                <Spinner size='sm' />
                            }
                            {!isLoading &&
                                <>
                                    Create new password
                                </>
                            }
                        </Button>
                    </Card.Body >

                </>
            }
        </>
    );
};
