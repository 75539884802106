import { Alert, Card, Text, FormGroup, Input, Label, Button } from '@efilecabinet/efc-atlantis-components';
import React, { useState } from 'react';
import { AuthProps, useAuthApi } from '../../../../api/useAuthApi';
import { IUseFormObject } from '../../../../utils/useForm';
import { useLoginNavigation } from '../../../../utils/useLoginNavigation';
import { useSSOAuth } from '../../../../utils/useSSOAuth';
import { RoutePath } from '../../Login';
import './SSOAllowFullAccess.css';

export interface RegionProps {
    loginForm: IUseFormObject<AuthProps>,
    show: boolean,
}

export const SSOAllowFullAccess = (props: RegionProps) => {

    const { loginForm, show } = props;

    const [dontAskAgain, setDontAskAgain] = useState(false);

    const { samlLogin } = useSSOAuth(loginForm);
    const { addSSOAuthWithoutFullUser } = useAuthApi();

    const navigate = useLoginNavigation();

    const onDontAllow = async () => {
        await addSSOAuthWithoutFullUser(loginForm.model, dontAskAgain);
        await samlLogin(loginForm.model.samlRoleAuthentication);
    };

    return (
        <>
            {show &&
                <>
                    <Card.Body>
                        <Alert color='primary'>
                            <Text>
                                If other accounts and/or products have different sign in requirements, you may experience limited access until you meet them.
                            </Text>
                        </Alert>

                        <Text>
                            To avoid limited access, do you want to allow “{loginForm.model.samlRoleAuthentication?.samlConfigurationName}” to sign you in to all of your accounts and integrated products (such as DocuSign, Google Drive, or One Drive)?
                        </Text>
                        <div className='mt-2 mb-3'>
                            <Text muted>
                                You can edit this setting later on the User Settings page.
                            </Text>
                        </div>

                        <Button className='w-100 mt-5' onClick={() => navigate(RoutePath.SSOGrantFullAccess)}>
                            Allow
                        </Button>
                        <Button className='w-100 my-2' emphasis='low' onClick={onDontAllow}>
                            Don{'\''}t allow
                        </Button>

                        <FormGroup className='mt-3'>
                            <Input className='checkbox' id='dontAskAgain' name='dontAskAgain' type='checkbox' checked={dontAskAgain} onChange={() => setDontAskAgain(current => !current)} />
                            <Label for='dontAskAgain'>Don{'\''}t ask me again</Label>
                        </FormGroup>
                    </Card.Body>
                </>
            }
        </>
    );
};
