import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react';
import { Button, FormFeedback, FormGroup, Icon, Input, Label, Spinner, Text, EfcLink } from '@efilecabinet/efc-atlantis-components';
import { useEmailFormValidator } from '../../../utils/useEmailFormValidator';
import { AuthenticationTypeEnum } from '../../../auth/AuthenticationTypes';
import { IUseFormObject } from '../../../utils/useForm';
import { useLoginHandler } from '../useLoginHandler';
import { AuthProps } from '../../../api/useAuthApi';
import './AccessLink.css';

export interface AccessLinkProps {
    loginForm: IUseFormObject<AuthProps>;
}

export const AccessLink = (props: AccessLinkProps) => {
    const { loginForm } = props;

    const { login } = useLoginHandler(loginForm);
    const { validateEmailForm } = useEmailFormValidator();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [agreedToEula, setAgreedToEula] = useState<boolean>(false);
    const [userName, setUserName] = useState<string | undefined>(undefined);

    const validateCharacters = (str: string) => {
        const regex = /^[a-zA-Z\s-]+$/;
        return regex.test(str);
    };

    const checkEmailForm = () => {
        if (validateEmailForm(loginForm.model.anonymousUserEmail as string)) {
            return true;
        }

        loginForm.addError('anonymousUserEmail', 'Invalid Email Address');
        return false;
    };

    const isFullName = () => {
        if (!!userName) {
            const nameArray = userName.split(' ');
            return nameArray.length >= 2;
        }

        return false;
    };

    const setFullName = () => {
        if (!!userName) {
            const nameArray = userName.trimEnd().split(' ');
            loginForm.setModelProperty('anonymousUserFirstName', nameArray[0]);
            loginForm.setModelProperty('anonymousUserLastName', nameArray[nameArray.length - 1]);
        }
    };

    const onUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const incoming = event.target.value;

        if (!incoming) {
            loginForm.addError('anonymousName', 'Provide your full name');
        } else if (!validateCharacters(incoming)) {
            loginForm.addError('anonymousName', 'No special characters or numbers allowed');
        } else {
            loginForm.clearError('anonymousName');
        }

        setUserName(incoming);
    };

    const trySubmit = async () => {
        if (!!loginForm.model.accessLinkNeedsInfo) {
            if (!isFullName()) {
                loginForm.addError('anonymousName', 'Provide your full name');
                return;
            }

            if (loginForm.model.anonymousUserEmail !== '' && !checkEmailForm()) {
                return;
            }

            setFullName();
            setIsLoading(true);
        }
        else {
            await login();
            loginForm.clearErrors();
        }
    };

    const onEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if ((!!userName || !loginForm.errors?.anonymousName) && !!agreedToEula) {
                trySubmit();
            }
        }
    };

    const onEulaChange = () => {
        setAgreedToEula((prevState) => !prevState);
    };

    const init = () => {
        loginForm.model.authenticationRequestType = AuthenticationTypeEnum.AccessLink;
        loginForm.model.newExperience = 'true';

        loginForm.setModelProperty('skipEulaCheck', true);

        if (!loginForm.model.anonymousUserFirstName) {
            loginForm.setModelProperty('anonymousUserFirstName', '');
        }

        if (!loginForm.model.anonymousUserLastName) {
            loginForm.setModelProperty('anonymousUserLastName', '');
        }

        if (!loginForm.model.anonymousUserEmail) {
            loginForm.setModelProperty('anonymousUserEmail', '');
        }
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (!!loginForm.model.skipEulaCheck) {
            trySubmit();
        }
    }, [loginForm.model.skipEulaCheck]);

    useEffect(() => {
        if (!!loginForm.model.accessLinkNeedsInfo) {
            setIsLoading(false);
        }
    }, [loginForm.model.accessLinkNeedsInfo]);

    useEffect(() => {
        const awaitLogin = async () => {
            await login();
        };

        if (!!loginForm.model.anonymousUserLastName && !!loginForm.model.anonymousUserFirstName) {
            awaitLogin();
        }

    }, [loginForm.model.anonymousUserFirstName, loginForm.model.anonymousUserLastName]);

    useEffect(() => {
        loginForm.clearErrors();
    }, [loginForm.errors?.modelErrors]);

    return (
        <>
            {isLoading && (
                <div className='text-center mt-5'>
                    <Spinner className='region-spinner mt-5' color='primary'>
                        Loading...
                    </Spinner>
                </div>
            )}

            {!isLoading && (
                <div className='access-link-wrapper'>
                    <Text semibold>To access this link, provide your full name.</Text>
                    <FormGroup className='mt-4'>
                        <Label for='username'>Full Name</Label>
                        <Input id='username' name='username' type='text' dataId='usernameAccessLink' value={userName} invalid={!!loginForm.errors?.anonymousName} onChange={onUserNameChange} onKeyUp={onEnterPress} autoFocus />
                        <FormFeedback className='position-fixed'>
                            <Icon iconName='exclamation-triangle' size='sm' className='icon-alignment' /> {loginForm.errors?.anonymousName}
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup className='mt-3'>
                        <Label for='anonymousUserEmail'>
                            Email <Text muted>(optional)</Text>
                        </Label>
                        <Input id='anonymousUserEmail' name='anonymousUserEmail' type='email' dataId='emailAccessLink' value={loginForm.model.anonymousUserEmail} invalid={!!loginForm.errors?.anonymousUserEmail} onChange={loginForm.onPropChanged} onKeyUp={onEnterPress} />
                        <FormFeedback className='position-fixed'>
                            <Icon iconName='exclamation-triangle' size='sm' className='icon-alignment' /> {loginForm.errors?.anonymousUserEmail}
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup className='access-link-footer'>
                        <Input className='checkbox' id='rememberMe' name='rememberMe' type='checkbox' checked={agreedToEula} onChange={onEulaChange} />
                        <Label for='rememberMe'>
                            I agree to the{' '}
                            <EfcLink newWindow href='https://www.revverdocs.com/terms-of-use/' className='text-primary'>
                                End User License Agreement <Icon iconName='external-link' className='icon-alignment' regular />
                            </EfcLink>
                        </Label>
                    </FormGroup>

                    <Button disabled={!userName || !!loginForm.errors?.anonymousName || !agreedToEula} onClick={trySubmit}>
                        Next
                    </Button>
                </div>
            )}
        </>
    );
};
