import { Card, Text, FormGroup, Input, Label, Spinner, Button, FormFeedback, Icon } from '@efilecabinet/efc-atlantis-components';
import React, { useEffect, useState } from 'react';
import { AuthProps, useAuthApi } from '../../../../api/useAuthApi';
import { IUseFormObject } from '../../../../utils/useForm';
import { useSSOAuth } from '../../../../utils/useSSOAuth';
import './SSOAllowFullAccess.css';

export interface RegionProps {
    loginForm: IUseFormObject<AuthProps>,
    show: boolean,
}

export const SSOGrantFullAccess = (props: RegionProps) => {

    const { loginForm, show } = props;

    const [resendSuccessful, setResendSuccessful] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { samlLogin } = useSSOAuth(loginForm);

    const { validateSSOPasscode, sendSSOPasscode } = useAuthApi();

    const handleKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && canSubmit()) {
            await onSubmit();
        }
    };

    const canSubmit = () => {
        return !!loginForm.model.samlRoleAuthorizeCode?.length
    };

    const onSubmit = async () => {
        if (!!loginForm.model.samlRoleAuthorizeCode) {
            setIsLoading(true);
            const response = await validateSSOPasscode(loginForm.model);
            if (response.isValid) {
                await samlLogin();
            }
            else {
                loginForm.addError('samlRoleAuthorizeCode', 'Invalid code. Try again.');
            }
            setIsLoading(false);
        }
    };

    const resendCode = async () => {
        setResendLoading(true);
        await sendSSOPasscode(loginForm.model);
        setResendLoading(false);

        setResendSuccessful(true);
        setTimeout(() => {
            setResendSuccessful(false);
        }, 3000);
    };

    useEffect(() => {
        if (!!show) {
            sendSSOPasscode(loginForm.model);
        }
        else {
            loginForm.setModelProperty('samlRoleAuthorizeCode', undefined);
            loginForm.setModelProperty('samlRoleAuthentication', undefined);
        }
    }, [show]);

    return (
        <>
            {show &&
                <>
                    <Card.Body>
                        <Text>
                            To grant full access to “{loginForm.model.samlRoleAuthentication?.samlConfigurationName}” the sign in code was sent to:
                        </Text>
                        <div className='mt-1 mb-5'>
                            <Text muted>
                                {loginForm.model.username}
                            </Text>
                        </div>

                        <FormGroup className='position-relative'>
                            <Button emphasis='low' color='primary' className='button-no-hover-color input-context-button' onClick={resendCode}>
                                {resendSuccessful &&
                                    <Icon className='my-auto' color='primary' iconName='circle-check' />
                                }
                                {!resendSuccessful && resendLoading &&
                                    <Spinner size='sm' className='me-2' />
                                }
                                Resend email
                            </Button>
                            <Label for='samlRoleAuthorizeCode'>
                                Email code
                            </Label>
                            <Input id='samlRoleAuthorizeCode' name='samlRoleAuthorizeCode' type='text' value={loginForm.model.samlRoleAuthorizeCode} invalid={!!loginForm.errors?.samlRoleAuthorizeCode} onChange={loginForm.onPropChanged} onKeyDown={handleKeyEvent} />
                            <FormFeedback>{loginForm.errors?.samlRoleAuthorizeCode}</FormFeedback>
                        </FormGroup>
                        <Button className='mx-auto w-100 my-5' onClick={onSubmit} disabled={!canSubmit()}>
                            {isLoading &&
                                <Spinner size='sm' className='my-auto' />
                            }
                            {!isLoading &&
                                <>
                                    Sign in
                                </>
                            }
                        </Button>
                    </Card.Body>
                </>
            }
        </>
    );
};
