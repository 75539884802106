import { AuthProps, EnvironmentDetails, useAuthApi } from '../../api/useAuthApi';
import { useLoginUtilities } from './useLoginUtilities';
import { IUseFormObject } from '../../utils/useForm';
import { AuthenticationTypeEnum, ExceptionTypeEnum, FailedAuthenticationRequirementsEnum, IAuthUser, ApplicationInfo } from '../AuthenticationTypes';
import secureLocalStorage from "react-secure-storage";

export interface ILoginActions {
    onLoginSuccess?: Function;
    onLoginFail?: Function;
    onLoginFinally?: Function;
}

export const useLoginHandler = (loginForm: IUseFormObject<AuthProps>) => {
    const authApi = useAuthApi();
    const { redirectToRubexExperience, hasSalesforceInQuery } = useLoginUtilities();

    const showError = (responseData: any) => {
        loginForm.addError('modelErrors', responseData.message);
    };

    const checkForMFA = (errorId?: any, message?: string): boolean => {
        if ((errorId === FailedAuthenticationRequirementsEnum.SecurityCode ||
            errorId === FailedAuthenticationRequirementsEnum.SecurityCodeAndLimitedAccess) &&
            message) {

            let appMFA = message.includes('mobile authenticator app');
            if (appMFA) {
                loginForm.setModelProperty('appMFA', true);
            } else {
                loginForm.setModelProperty('appMFA', false);
            }

            let emailMFA = message.includes('A security code was sent to your email');
            if (emailMFA) {
                loginForm.setModelProperty('emailMFA', true);
            } else {
                loginForm.setModelProperty('emailMFA', false);
            }

            if (appMFA || emailMFA) {
                loginForm.setModelProperty('requiresMFA', true);
                return true;
            }
        }

        return false;
    };

    const checkForExpiredPassword = (errorId?: any): boolean => {
        if (errorId === FailedAuthenticationRequirementsEnum.ExpiredPassword ||
            errorId === FailedAuthenticationRequirementsEnum.ExpiredPasswordAndLimitedAccess) {
            loginForm.setModelProperty('passwordExpired', true);
            return true;
        }
        return false;
    };

    const checkForLimitedAccess = (errorId?: any): boolean => {
        if (errorId === FailedAuthenticationRequirementsEnum.LimitedAccess ||
            errorId === FailedAuthenticationRequirementsEnum.SecurityCodeAndLimitedAccess ||
            errorId === FailedAuthenticationRequirementsEnum.OutgoingEmailSettingAndLimitedAccess ||
            errorId === FailedAuthenticationRequirementsEnum.ExpiredPasswordAndLimitedAccess) {
            loginForm.setModelProperty('limitedAccess', false);
            return true;
        }
        return false;
    };

    const checkForOutgoingEmailSetting = (errorId?: any): boolean => {
        if (errorId === FailedAuthenticationRequirementsEnum.OutgoingEmailSetting ||
            errorId === FailedAuthenticationRequirementsEnum.OutgoingEmailSettingAndLimitedAccess) {
            loginForm.setModelProperty('requiresEmailSetting', true);
            return true;
        }
        return false;
    };

    const checkForAnonymousAccess = (errorId?: any): boolean => {
        if (errorId === FailedAuthenticationRequirementsEnum.AdditionalInformation) {
            loginForm.setModelProperty('accessLinkNeedsInfo', true);
            return true;
        }
        return false;
    };

    const checkForAgreedToEula = (message?: any): boolean => {
        if (message.toLowerCase().includes('eula')) {
            loginForm.setModelProperty('requiresEulaAgreement', true);
            return true;
        }
        loginForm.setModelProperty('requiresEulaAgreement', false);
        return false;
    };

    const checkAccessLinkRemainingUses = (message?: string): boolean => {
        if (message?.toLowerCase().includes('link has no uses')) {
            loginForm.setModelProperty('accessLinkNoUsesRemaining', true);
            return true;
        }
        loginForm.setModelProperty('accessLinkNoUsesRemaining', false);
        return false;
    };

    const checkAccessLinkExpiration = (message?: string): boolean => {
        if (message?.toLowerCase().includes('expired')) {
            loginForm.setModelProperty('accessLinkExpired', true);
            return true;
        }
        loginForm.setModelProperty('accessLinkExpired', false);
        return false;
    };

    const checkUploadLinkMissing = (message?: string): boolean => {
        if (message?.toLowerCase().includes('link is no longer valid')) {
            loginForm.setModelProperty('uploadLinkMissing', true);
            return true;
        }
        loginForm.setModelProperty('uploadLinkMissing', false);
        return false;
    };

    // Returns true if error was handled, false if it was not
    const handleError = (error: any) => {
        let errorData = error?.response?.data;
        if (!errorData) {
            // we couldn't connect to utopia or something
            showError(error);
            return false;
        }

        if (!!errorData.error_description) {
            errorData = JSON.parse(errorData.error_description);
        }

        if (errorData.errorType === ExceptionTypeEnum.Authentication || errorData.exceptionType === ExceptionTypeEnum.Authentication) {
            checkForLimitedAccess(errorData.errorId);

            const hasMFA = checkForMFA(errorData.errorId, errorData.message);
            const isAnonymous = checkForAnonymousAccess(errorData.errorId);
            const needsOutgoingEmailSetting = checkForOutgoingEmailSetting(errorData.errorId);
            const passwordExpired = checkForExpiredPassword(errorData.errorId);
            const needsEula = checkForAgreedToEula(errorData.message);
            const accessLinkNoRemainingUses = checkAccessLinkRemainingUses(errorData.message);
            const accessLinkExpired = checkAccessLinkExpiration(errorData.message);
            const uploadLinkMissing = checkUploadLinkMissing(errorData.message);

            if (hasMFA || isAnonymous || needsOutgoingEmailSetting || passwordExpired || needsEula || accessLinkNoRemainingUses || accessLinkExpired || uploadLinkMissing) {
                return true;
            }
        }

        if (loginForm.model.limitedAccess) {
            return true;
        }

        showError(error.response.data);
        return false;
    };

    const saveAuthDetailsToLocalStorage = (authUser: IAuthUser) => {
        localStorage.setItem('uid', authUser.accessToken);

        localStorage.setItem('loginUserId', JSON.stringify(authUser.userID));

        const copiedEnvironmentDetails: EnvironmentDetails = {
            cname: loginForm.model.cname,
            isSalesforce: loginForm.model.isSalesforce,
            newExperience: loginForm.model.newExperience,
            regionIdentifier: loginForm.model.regionIdentifier,
            revverInstance: loginForm.model.revverInstance,
            userId: authUser.userID
        };
        localStorage.setItem('loginEnvironmentDetails', JSON.stringify(copiedEnvironmentDetails));

        if (!!loginForm.model.securityCode) {
            secureLocalStorage.setItem("mfaToken", loginForm.model.securityCode);
        }
    };

    const login = async (loginActions?: ILoginActions, limitedAccess: boolean = false) => {
        loginForm.clearErrors();

        if (!loginForm.model.authenticationRequestType) {
            loginForm.model.authenticationRequestType = AuthenticationTypeEnum.Username_Password;
        }

        try {
            let appInfo = {} as ApplicationInfo;
            const clientInfo = localStorage.getItem('applicationInfo');

            if (clientInfo) {
                appInfo = JSON.parse(clientInfo);
            }

            let authUser = await authApi.login({ ...loginForm.model, limitedAccess, appInfo });

            if (hasSalesforceInQuery()) {
                loginForm.model.isSalesforce = true;
            }

            saveAuthDetailsToLocalStorage(authUser);

            await redirectToRubexExperience(loginForm.model);

            if (loginActions?.onLoginSuccess) {
                loginActions.onLoginSuccess();
            }
        } catch (error: any) {
            const errorHandled = handleError(error);

            if (loginActions?.onLoginFail) {
                loginActions.onLoginFail(error, errorHandled);
            }
        } finally {
            if (loginActions?.onLoginFinally) {
                loginActions.onLoginFinally();
            }
        }
    };

    const loginWithLimitedAccess = async () => {
        const limitedAccess = true;
        const loginActions = undefined;
        await login(loginActions, limitedAccess);
    };

    return {
        login,
        loginWithLimitedAccess,
        redirectToRubexExperience
    };
};
