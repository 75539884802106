//import { Node_RC } from '../pages/Documents/Documents/RecycleBin/useRecycleBinApi';
import { AuthProps } from '../api/useAuthApi';
import { IUseFormObject } from '../utils/useForm';
import { AnonymousEndpointAccess, IEmailSetting } from './Login/OutgoingEmail/OutgoingEmailTypes';

// ENUMS

export enum AccountFeatureEnum {
    StandardUserLicense = 1,
    GuestUserLicense = 2,
    Governance = 3,
    FileStorageSize = 4,
    FileVersioning = 5,
    Templates = 6,
    CheckInOut = 7,
    AccusoftPreview = 8,
    ZonalOCRLicense = 11,
    AccountTemplateFeature = 12,
    FullTextSearch = 13,
    PersonalProviders = 14,
    DocumentRequests = 15,
    GovernanceLock = 16,
    AccusoftOCR = 17,
    Workflow = 19,
    Salesforce = 20,
    ItemStatus = 21,
    EnterpriseSecurity = 22,
    GovernanceUnlock = 23,
    Branding = 24,
    GuestUserPreview = 25,
    FilePassword = 26,
    GuestUserSearch = 27,
    ConcurrentLicensing = 28,
    EmailFiles = 29,
    BetaAccount = 30,
    Records = 31,
    SSO_SAML = 32,
    EmailImport = 33,
    PreviewerImageStamps = 34,
    ESignature = 35,
    DualScreenPreview = 36,
    ESignatureKBA = 37,
    ESignatureOTP = 38,
    AnonymousAccessLinks = 39,
    SearchReports = 40,
    Watermark = 41,
    Reporting = 42,
    FormFill = 43,
    PriorityOCR = 44,
    O365 = 45,
    WorkflowStepsPerWorkflow = 46,
    AbbyFineReaderOCR = 47,
    LimitedUserLicense = 49,
    PublicShareSearch = 50,
}

export enum AuthenticationTypeEnum {
    System = 0,
    Username_Password = 1,
    SSO_Saml = 2,
    AccessLink = 3,
    SSO_Saml_Full_Access = 4,
}

export enum ExceptionTypeEnum {
    SystemException = 0,
    General = 1,
    Authentication = 2,
    Unauthorized = 3,
    Template = 4,
    NotImplemented = 5,
    OCR = 6,
    AccountFeatureUsage = 7,
}

export enum ExternalAuthenticationAreaEnum {
    Account,
    User,
}

export enum ExternalAuthenticationProviderEnum {
    GoogleDrive = 1,
    OneDrive = 2,
    Docusign = 3,
    Gmail = 5,
    Outlook = 6,
}

export enum FailedAuthenticationRequirementsEnum {
    None = 0,
    SecurityCode = 1,
    LimitedAccess = 2,
    SecurityCodeAndLimitedAccess = 3,
    Credentials = 4,
    AdditionalInformation = 5,
    MaxTimeoutSession = 6,
    SamlProviderNotAuthorizedToAuthenticateAsFullUser = 7,
    OutgoingEmailSetting = 8,
    OutgoingEmailSettingAndLimitedAccess = 9,
    InvalidResetCode = 10,
    ExpiredResetCode = 11,
    ExpiredPassword = 12,
    ExpiredPasswordAndLimitedAccess = 13,
}

export enum MfaTypeEnum {
    App = 0,
    Email = 1,
    Either = 2,
}

export enum RoleEnum {
    //Guest = 1,
    User = 2,
    Group = 3,
}

export enum SystemPermissionEnum {
    ItemPermissionsControl = 0,
    ItemPermissionsOverride = 1,
    SystemSettings = 2,
    SalesforceManagement = 3,
    AuditLogManagement = 4,
    ViewAuditLogs = 5,
    PersonalProviders = 6,
    DocumentRequests = 7,
    DocumentRequestManagement = 8,
    FormFill = 9,
    ESignature = 10,
    ESignatureManagement = 11,
    Profiles = 12,
    ProfileManagement = 13,
    FileManagement = 14,
    TemplateManagement = 15,
    FileVersioning = 16,
    FilePassword = 17,
    Search = 18,
    CheckInOut = 19,
    PreviewerTools = 20,
    CreateModifyStructure = 21,
    Governance = 22,
    GovernanceAdmin = 23,
    Sharing = 24,
    AccessLinks = 25,
    AccessLinkManagement = 26,
    UserManagement = 27,
    SessionManagement = 28,
    GroupManagement = 29,
    Workflow = 30,
    WorkflowApproval = 31,
    WorkflowManagement = 32,
    Automation = 33,
    RubexReports = 34,
    EnterpriseSecurityManagement = 35,
}

export enum UserLicenseEnum {
    Standard = AccountFeatureEnum.StandardUserLicense,
    Limited = AccountFeatureEnum.LimitedUserLicense,
    Guest = AccountFeatureEnum.GuestUserLicense,
}

// INTERFACES

export interface IAuthContext {
    authUser: IAuthUser | null;
    user: IUser | null;
    handleLogin: (loginProps: IUseFormObject<AuthProps>) => Promise<void>;
    handleLogout: () => void;
    getIsAuthorized: () => Promise<boolean>;
    getBaseUrl: () => string | null;
}

export interface IAuthUser {
    accountID: number;
    userID: number;
    accessToken: string;
    refreshToken?: string;
    expiresIn?: number;
    mfaToken?: string;
    userType: number;
    displayName: string;
    multipleAccounts: boolean;
    samlConfigId?: number;
    eSigProduct?: number;
    systemPermissionsToAccounts: SystemPermissions[];
    userRoles: Role[];
}

export interface ExternalOAuth {
    authenticationType: ExternalAuthenticationProviderEnum;
    displayName: string;
    isAuthenticated: boolean;
    authenticationUrl: string;
    logoUrl: string;
    area: ExternalAuthenticationAreaEnum;
}

export interface RevverInstance {
    utopiaInstanceId: string;
    utopiaUrl: string;
    name: string;
}

export interface Role {
    id: number;
    accountID: number;
    userID?: number;
    profileID?: number;
    roleType: RoleEnum;
    username: string;
    name: string;
    license?: UserLicenseEnum;
    systemPermissions: SystemPermissions;
    memberOfRoles: Role[];
    roleMembers: Role[];
    securityPolicyID?: number;
    roleManagerID?: number;
    anonymous: boolean;
    includeInSalesforceMapping: boolean;
    ldapID: number;
}

export interface SystemPermission {
    name: string;
    description: string;
    systemPermissionType: SystemPermissionEnum;
}

export interface SystemPermissions {
    permissionsList: SystemPermission[];
    accountID: number;
}

export interface IUser {
    id: number;
    securitySettings: IUserSecuritySettings;
    preferences: IUserPreferences;
    emailSettings: IEmailSetting[];
    //CustomPreviewerStamps: Node_RC;
    username: string;
    sessionExpire: number;
}

export interface UserAnonymous {
    user: IUser;
    anonymousEndpointAccess: AnonymousEndpointAccess;
}

export interface IUserPreferences {
    activeEmailSettingID?: number;
    checkOutOnDoubleClick?: boolean;
    alwaysConfirmAppend: boolean;
    deleteAppendedNode: boolean;
    defaultPortfolioID?: number;
    timeZoneId: string;
    useStoreArea: boolean;
    searchDefaultByName: boolean;
    searchDefaultByProfileValue: boolean;
    searchDefaultByCreatedByName: boolean;
    searchDefaultByComment: boolean;
    searchDefaultByFullText: boolean;
    searchDefaultByPath: boolean;
    searchDefaultUseContainsWordsIn: boolean;
    skipSSOFullUserPrompt: boolean;
}

export interface IUserSecuritySettings {
    oldPassword: string;
    newPassword: string;
    mfaIsRequired: boolean;
    mfaType: MfaTypeEnum;
    mfaRequiredDays?: number;
    mobilePhoneNumber: string;
    mobilePhoneCountryCode: string;
}

export interface ApplicationInfo {
    clientId?: string;
    clientSecret?: string;
    version?: string;
}