import Api from '../api'
import { AxiosResponse } from 'axios';
import { ISecurityPolicy } from '../utils/CommonTypes';

export enum ResetPasswordTypeEnum {
    Email = 0,
    TextMessage = 1,
}

export const useResetPasswordApi = () => {

    const sendResetRequest = async (username: string, instanceName: string, resetType: ResetPasswordTypeEnum, isNewUser?: boolean) => {
        await Api.post('api/resetpassword/SendResetRequest', { username: username, instanceName: instanceName, resetType: resetType, isNewUser: isNewUser });
    };

    const submitNewPassword = async (username: string, instanceName: string, userId: number, newPassword: string, resetToken: string | undefined, expiredPassword: string | undefined): Promise<boolean> => {
        const resp: AxiosResponse = await Api.post('api/resetpassword/SubmitNewPassword', { username: username, instanceName: instanceName, userId: userId, newPassword: newPassword, resetToken: resetToken, expiredPassword: expiredPassword });
        return resp.data as boolean;
    };

    const validateResetToken = async (username: string, instanceName: string, userId: number, resetToken: string): Promise<boolean> => {
        const resp: AxiosResponse = await Api.post('api/resetpassword/ValidateResetToken', { username: username, instanceName: instanceName, userId: userId, resetToken: resetToken });
        return resp.data as boolean;
    };

    const getUserSecurityPolicies = async (username: string, instanceName: string, userId: number, resetToken: string | undefined, expiredPassword: string | undefined): Promise<ISecurityPolicy[]> => {
        const resp: AxiosResponse = await Api.post('api/resetpassword/SecurityPolicies', { username: username, instanceName: instanceName, userId: userId, resetToken: resetToken, expiredPassword: expiredPassword });
        return resp.data as ISecurityPolicy[];
    };

    return {
        sendResetRequest,
        submitNewPassword,
        validateResetToken,
        getUserSecurityPolicies,
    };
};
