import { Modal, EfcCallToActionInfo } from '@efilecabinet/efc-atlantis-components';
import { useState, useEffect } from 'react';
import { AuthProps, IdentityProvider  } from '../../../api/useAuthApi';
import { SamlResponseStatus, useSamlApi } from '../../../api/useSamlApi';
import { CreateCenteredPopup } from '../../../utils/PopupUtility';
import { IUseFormObject } from '../../../utils/useForm';
import { useSSOAuth } from '../../../utils/useSSOAuth';

export interface SSOAuthObj {
    signInWithSSO: boolean;
    setSignInWithSSO: (signIn: boolean) => void;
    activeSSOProvider: IdentityProvider | undefined;
    samlResponseStatus: SamlResponseStatus;
    setSamlResponseStatus: (status: SamlResponseStatus) => void;
}

interface SSOLoginProps {
    loginForm: IUseFormObject<AuthProps>;
    ssoAuthObj: SSOAuthObj;
}

export const SSOAuth = (props: SSOLoginProps) => {

    const LOGIN_TIMEOUT_IN_SECONDS = 120;
    const samlApi = useSamlApi();

    const { loginForm, ssoAuthObj } = props;
    const { signInWithSSO, setSignInWithSSO, activeSSOProvider, samlResponseStatus, setSamlResponseStatus } = ssoAuthObj;
    const { samlLogin } = useSSOAuth(loginForm);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorModalUrl, setErrorModalUrl] = useState('');

    const toggleShowErrorModal = () => {
        setShowErrorModal(!showErrorModal);
    };

    const checkForSamlResponse = async (samlResponse: SamlResponseStatus, triesRemaining: number) => {
        if (samlResponse.samlConfigurationId !== samlResponseStatus?.samlConfigurationId || samlResponseStatus?.hasData) {
            return;
        }

        const response = await samlApi.checkSamlResponseStatus(samlResponse.id as string, loginForm.model);

        if (!!response && response.hasData) {
            response.samlConfigurationId = samlResponseStatus?.samlConfigurationId;
            loginForm.model.samlToken = samlResponseStatus.id;
            setSamlResponseStatus(response);
        }
        else if (!!response) {
            triesRemaining--;
            setTimeout(() => {
                checkForSamlResponse(samlResponse, triesRemaining);
            }, 1000);
        }
    };

    const runSSOAuthenticationPopup = () => {
        if (!samlResponseStatus?.id) {
            return;
        }

        const baseUrl = loginForm.model.revverInstance?.utopiaUrl;
        const endpoint = 'api/BrowserAuthentication/' + samlResponseStatus?.id + '/SamlAuth';
        const url = baseUrl + endpoint;
        const popup = CreateCenteredPopup(url, 'SSO Sign In', 550, 550);

        if (!popup) {
            toggleShowErrorModal();
            setErrorModalUrl(url);
        }
    };

    const ssoLogin = async (ssoProvider: IdentityProvider) => {
        if (!!samlResponseStatus && samlResponseStatus.samlConfigurationId === ssoProvider.samlConfigurationId && !!samlResponseStatus.id) {
            runSSOAuthenticationPopup();
            return;
        }

        const responseStatus = await samlApi.beginSamlAuth(ssoProvider.samlConfigurationId, loginForm.model);

        if (!!responseStatus) {
            responseStatus.samlConfigurationId = ssoProvider.samlConfigurationId;
            setSamlResponseStatus(responseStatus);
        }
    };

    const confirmationCTAs: EfcCallToActionInfo[] = [
        { text: 'Close', color: 'primary', emphasis: 'med', onClick: () => toggleShowErrorModal() },
    ];

    useEffect(() => {
        if (!!samlResponseStatus.id && !samlResponseStatus.hasData) {
            runSSOAuthenticationPopup();
            setTimeout(() => {
                checkForSamlResponse(samlResponseStatus, LOGIN_TIMEOUT_IN_SECONDS);
            }, 1000);
            return;
        }

        if (samlResponseStatus.hasData) {
            samlLogin();
            return;
        }

    }, [samlResponseStatus]);

    useEffect(() => {
        if (!signInWithSSO) {
            return;
        }

        ssoLogin(activeSSOProvider as IdentityProvider);
        setSignInWithSSO(false);

    }, [signInWithSSO]);

    return (
        <>
            <Modal toggle={toggleShowErrorModal} title='SSO Sign In Error' isOpen={showErrorModal} ctas={confirmationCTAs} >
                <Modal.Body>
                    If you have a pop-up blocker, please disable it and refresh the page.  Or simply <a href={errorModalUrl} target="_blank" onClick={toggleShowErrorModal }>click here</a> to manually sign in again.
                </Modal.Body>
            </Modal>
        </>
    );
};